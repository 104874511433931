 (function() {
	angular
		.module('prods', [ 'ngAnimate', 'ngTouch', 'ngSanitize', 'ui.grid',  'ui.grid.autoResize', 'ui.grid.saveState', 'ui.grid.selection', 'ui.grid.autoResize', 'ui.grid.exporter', 'ui.bootstrap', 'flow', 'ui.router', 'dialogs.main', 'dialogs.default-translations', 'ui.checkbox', 'ui-notification', 'sprintf', 'LocalStorageModule', 'angular-medium-editor', 'ngclipboard', 'chart.js', 'main'])
		.config(['flowFactoryProvider', '$stateProvider', '$urlRouterProvider', '$translateProvider', 'localStorageServiceProvider', '$logProvider', '$compileProvider', function(flowFactoryProvider, $stateProvider, $urlRouterProvider, $translateProvider, localStorageServiceProvider, $logProvider, $compileProvider) {

			$logProvider.debugEnabled(false);
			$compileProvider.commentDirectivesEnabled(false);
			$compileProvider.cssClassDirectivesEnabled(false);

			$config = {
				'financialYear': 2019
			};

			flowFactoryProvider.defaults = {
				target : 'uploadBackend.php',
				maxChunkRetries : 1,
				chunkRetryInterval : 5000,
				simultaneousUploads : 1
			};
			flowFactoryProvider.on('catchAll', function(event) {
				//console.log('catchAll', arguments);
			});
			// Can be used with different implementations of Flow.js
			// flowFactoryProvider.factory = fustyFlowFactory;

			$urlRouterProvider.otherwise('/');

			$stateProvider.state('viewProds', {
					url : "/productions/:customerCode/:userId",
					templateUrl : 'partials/viewProductionAccounts.html'
				}).state('searchProductions', {
					url : '/searchProduction/:year/:query',
					templateUrl : 'partials/searchProduction.html'
				}).state('viewOrders', {
					url : '/orders',
					templateUrl : 'partials/viewOrders.html'
				}).state('viewInvoices', {
					url : '/invoices/:supplier',
					templateUrl : 'partials/viewInvoices.html'
				}).state('viewProduction', {
					url : '/production/{productionId}',
					templateUrl : 'partials/viewProduction.html'
				}).state('viewProductionTab', {
					url : '/production/{productionId}#{tabNumber}',
					templateUrl : 'partials/viewProduction.html'
				}).state('viewQuotes', {
					templateUrl : 'partials/viewQuotes.html',
					url : '/quotes',
				}).state('viewProducers', {
					templateUrl : 'partials/producers.html',
					url: '/producers'
				}).state('editCustomerInvoice', {
					url : '/customerInvoice/:invoiceId',
					templateUrl : 'partials/editCustomerInvoice.html'
				}).state('viewWages', {
					url : '/wages',
					templateUrl : 'partials/viewWages.html'
				}).state('viewEmployees', {
					url : '/employees',
					templateUrl : 'partials/viewEmployees.html'
				}).state('viewSuppliers', {
					url : '/suppliers',
					templateUrl : 'partials/viewSuppliers.html'
				}).state('viewDeclarationPeriods', {
					url : '/declarationPeriods',
					templateUrl : 'partials/viewDeclarationPeriods.html'
				}).state('viewExpenses', {
					url : '/expenses',
					templateUrl : 'partials/viewExpenses.html'
				}).state('home', {
					templateUrl : 'partials/home.html',
					url: '/'
				}).state('mobile', {
					templateUrl : 'partials/mobileDashboard.html',
					url: '/'
				}).state('lock', {
					templateUrl : 'partials/lock.html',
					url: '/lock'
				});

			$translateProvider.preferredLanguage('fr-FR');

			localStorageServiceProvider.setPrefix('pwpProds');
		}]).run(['$rootScope', function ($rootScope){
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			$rootScope.device = {
				isMobile: 	userAgent.match(/ipad|iphone|ipod|android/i) ? true : false,
				isDesktop: 	userAgent.match(/ipad|iphone|ipod|android/i) ? false : true,
				isIos: 	userAgent.match(/ipad|iphone|ipod/i) ? true : false,
				isAndroid: userAgent.match(/android/i) ? true : false,
				isTrident: userAgent.match(/Trident/i) ? true : false
			};
		}]).directive('ngModelOnblur', function() {
		// override the default input to update on blur
			return {
				restrict: 'A',
				require: 'ngModel',
				priority: 1, // needed for angular 1.2.x
				link: function(scope, elm, attr, ngModelCtrl) {
					if (attr.type === 'radio' || attr.type === 'checkbox') return;

					elm.unbind('input').unbind('keydown').unbind('change');
					elm.bind('blur', function() {
						scope.$apply(function() {
							ngModelCtrl.$setViewValue(elm.val());
						});
					});
				}
			};
		});
	angular.module('main', []);
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('editCustomerInvoiceCtrl', ['$scope', '$rootScope', '$uibModal', '$log', '$http', '$filter', '$timeout', 'jsonData', 'uiGridConstants', 'localStorageService', EditCustomerInvoiceCtrl]);

	function EditCustomerInvoiceCtrl($scope, $rootScope, $uibModal, $log, $http, $filter, $timeout, jsonData, uiGridConstants, localStorageService) {
		// Properties
		$scope.recipient={
			recipientName: '',
			recipientAddress: '',
			recipientVatId: ''
		};

		// Init

		$http.get('/api/invoiceRecipients/',{}).then(
			function(response) {
				$scope.recipients = response.data;
				$log.debug('Invoice Recipients');
				$log.debug($scope.recipients);
			},
			function(httpError){
				throw httpError.status + " : " + httpError.data;
			}
		);

		$scope.selectedProduction = undefined;
		jsonData.getProductions('').then(function(data){
			$scope.productions=data;
		});

		$scope.$watch('selectedProduction.code', function(newValue) {
			if(newValue !== undefined){
				$state.go('viewProduction', {"productionId" : newValue});
				$scope.selectedProduction = undefined;
			}
		});

		// Methods

		$scope.getMatchingProductions = function($viewValue) {
			var matchingProductions = [];
			for (var i=0; i< $scope.productions.length; i++) {
				if (
					$scope.productions[i].description.toLowerCase().indexOf($viewValue.toLowerCase()) != -1 ||
					$scope.productions[i].code.toLowerCase().indexOf($viewValue.toLowerCase()) != -1 ) {

				matchingProductions.push($scope.productions[i]);
				}
			}
			return matchingProductions;
		};

		$scope.newRecipient = function (){
			$log.debug($scope.recipient);
			$http.post('/api/invoiceRecipients/', $scope.recipient).then(
				function(response) {
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('globalCtrl', ['$scope', '$rootScope', '$uibModal', '$log', '$http', '$timeout', '$state', '$location', 'jsonData', 'Notification', 'dialogs', 'localStorageService', globalCtrl ]);


	function globalCtrl($scope, $rootScope, $uibModal, $log, $http, $timeout, $state, $location, jsonData, Notification, dialogs, localStorageService) {

		localStorageService.remove('productionsGridState');
		localStorageService.remove('productionsStatusFilters');
		localStorageService.remove('productionsFilters');

		$scope.$on('flow::filesSubmitted', function (event, $flow) {
			$scope.parseResults = [];
			//$scope.openResult();
			$flow.upload();
		});

		$scope.getSubject = function(){
			return $scope.subject;
		};

		$scope.$on('flow::fileSuccess', function (event, $flow, flowFile, message) {
			$log.debug(sprintf('flow::fileSuccess', flowFile.name));
			$flow.removeFile(flowFile);
			$http.post('parseSpreadsheet.php', {'fileName' : flowFile.name}).then(
				function(response) {
					if(response.data.documentType==='customerQuote') {
						$scope.parseResults = response.data;
						Notification(response.data.code+' mis à jour.');
						$state.go('viewProduction', {"productionId" : response.data.code}, {reload: true});
						var dlg = dialogs.confirm(sprintf('Mise à jour de %s', response.data.quoteNumber), 'Cette version a t elle été transmise au client ?', {'size' : 'sm'});
						dlg.result.then(function(btn){
							if(btn==='yes'){
								$http.post('/json/emailCustomerQuote.php', {
									'quoteNumber'	: response.data.quoteNumber
								});
							}
						});
						//var modalInstance = $scope.openModal('./partials/modalParseResults.html', 'modalParseResultsCtrl', {'parserResults' : data}, '');
					}

					if(response.data.documentType==='internalQuote'){
						if(response.data.error){
							Notification.error(response.data.errorText);
						} else {
							$scope.parseResults = response.data;
							Notification.info(sprintf('%s mis à jour.', response.data.code));
						}
						$state.go('viewProduction', {"productionId" : response.data.code}, {reload: true});
					}
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		});

		$scope.openModal = function (template, controller, params, size) {
			var modalInstance = $uibModal.open({
				backdrop: 'static',
				templateUrl: template,
				controller: controller,
				scope: $scope,
				resolve: {
					params: function () {
						return params;
					}
				},
				size: size
			});
			return modalInstance;
		};

		$scope.selectedSupplier = undefined;

		jsonData.getSuppliers({}).then(function(data){
			$scope.suppliers=data;
		});

		$scope.selectedProduction = undefined;
		jsonData.getProductions('').then(function(data){
			$scope.productions=data;
		});

		$scope.getMatchingProductions = function($viewValue) {
			var matchingProductions = [];
			for (var i=0; i< $scope.productions.length; i++) {
				if (
					$scope.productions[i].description.toLowerCase().indexOf($viewValue.toLowerCase()) != -1 ||
					$scope.productions[i].code.toLowerCase().indexOf($viewValue.toLowerCase()) != -1 ) {

				matchingProductions.push($scope.productions[i]);
				}
			}
			return matchingProductions;
		};

		$scope.checkLock = function(){
			$http.post('json/isAppLockedJSON.php')
			.then(
				function(result) {
					//$log.info(eval(result.isLocked));
					$rootScope.appLocked=parseInt(result.isLocked);
					if($rootScope.appLocked)
						$state.go('lock',{});
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.viewProduction = function($item, $model, $label) {
			$log.debug ('production selected');
			$log.debug ($item);
			if($item){
				$scope.$applyAsync(function() {
					$state.go('viewProduction', {"productionId" : $item.code});
					$scope.selectedProduction = undefined;
				});
			}
		};

		$scope.onSignIn = function (googleUser) {
			var userProfile =  googleUser.getBasicProfile();
			//$log.debug('Signed in');
			//$log.debug(userProfile);
			$scope.$applyAsync( function () {
				$rootScope.profile = userProfile;
				switch($rootScope.profile.getEmail()) {
					case 'patrick.dalbin@parkerwaynephilips.com':
					case 'phmetzger@parkerwaynephilips.com':
					case 'compta@parkerwaynephilips.com':
						$rootScope.profile.isAdmin = true;
					break;

					default:
						$rootScope.profile.isAdmin = false;
						break;
				}
			});
		};

		$scope.signOut = function () {
			var auth2 = gapi.auth2.getAuthInstance();
			auth2.signOut().then( function() {
				//$log.debug('User signed out.');
				$scope.$applyAsync(function () {
					$rootScope.profile = {};
				});
			});
		};

		gapi.load('auth2', function() {
			gapi.auth2.init({
				client_id: '928233436431-e88nktcuo6ebsam11ovtgs39qff5rceh.apps.googleusercontent.com',
				hosted_domain: 'parkerwaynephilips.com'
			});
			//$log.debug(gapi.auth2.getAuthInstance());
		});

		gapi.signin2.render('my-signin2', {
			'longtitle': false,
			'theme': 'light',
			'prompt': 'select_account',
			'onsuccess': $scope.onSignIn
		});
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('homeCtrl', ['$scope', '$rootScope', '$uibModal', '$log', '$http', '$filter', '$timeout', 'jsonData', 'uiGridConstants', 'localStorageService', homeCtrl]);

	function homeCtrl($scope, $rootScope, $uibModal, $log, $http, $filter, $timeout, jsonData, uiGridConstants, localStorageService) {
		$scope.customersStats = [];
		$scope.activeCustomersStats = [];

		$scope.activeCustomers = true;
		$scope.fundingsSum = 0;
		$scope.productionCount = 0;
		$scope.totalCost = 0;
		$scope.engagedQuotesSum = 0;
		$scope.pendingQuotesSum = 0;
		$scope.currentQuotesSum = 0;
		$scope.canceledQuotesSum = 0;
		$scope.closedQuotesSum = 0;


		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
			jsonData.getProductions().then(function(data){
				$log.debug('getProductions');
				$log.debug(data);
				angular.forEach($scope.customers, function(customer, key) {
					var productionsArray = $scope.productions.filter(FilterByCustomerCode, {code: customer.code});
					var fundingsSum = 0;
					var productionCount = 0;
					var totalCost = 0;
					var engagedQuotesSum = 0;
					var pipeSum = 0;
					var canceledQuotesSum = 0;
					var globalMargin = 0;

					$scope.productionCount=data.length;
					angular.forEach(productionsArray, function(production, key){
						productionCount ++;

						if (!production.transparent) {
							if (production.status<4) {
								canceledQuotesSum += production.canceledFundings;
								engagedQuotesSum += production.quotesSum;
								$scope.engagedQuotesSum += parseFloat(production.quotesSum);
								if (production.status>0) {
									fundingsSum += production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
									$scope.fundingsSum += production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
								}
							} else {
								canceledQuotesSum += parseFloat(production.canceledFundings);
								$scope.canceledQuotesSum += parseFloat(production.canceledFundings);
							}

							switch (parseInt(production.status)){
								case 0:
									totalCost += production.invoicesSum + production.wagesSum + production.expensesSum;
									$scope.totalCost += parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
									pipeSum += production.pendingQuotesSum + production.extraFundings + production.partialClosure - production.carriedForwardFundings;
									$scope.pipeSum += production.pendingQuotesSum + production.extraFundings + production.partialClosure - production.carriedForwardFundings;
									break;

								case 1:
								case 2:
									totalCost += parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
									$scope.totalCost += parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
									$scope.currentQuotesSum += parseFloat(production.quotesSum);
									pipeSum += production.pendingQuotesSum;
									$scope.pipeSum += production.pendingQuotesSum;
									break;

								case 3:
									totalCost += production.invoicesSum + production.wagesSum + production.expensesSum;
									$scope.totalCost += production.invoicesSum + production.wagesSum + production.expensesSum;
									$scope.closedQuotesSum += parseFloat(production.quotesSum);
									break;

								case 4:
									$scope.totalCost += production.invoicesSum + production.wagesSum + production.expensesSum;
									totalCost += production.invoicesSum + production.wagesSum + production.expensesSum;
									break;
							}
						}
					});

					if (fundingsSum!==0)
						globalMargin = (1 - totalCost / fundingsSum) * 100;
					else
						globalMargin = -Infinity;

					var successRate=0;
					if (fundingsSum)
						successRate = (1-(canceledQuotesSum/(fundingsSum+canceledQuotesSum)))*100;

					var customerStats = {
						'code' : customer.code,
						'name' : customer.name,
						'count' : productionCount,
						'pipeSum' : pipeSum,
						'engagedQuotesSum' : engagedQuotesSum,
						'canceledQuotesSum' : canceledQuotesSum,
						'fundingsSum' : fundingsSum,
						'totalCost'	: totalCost,
						'marginAmount' : fundingsSum-totalCost,
						'successRate' : successRate,
						'globalMargin' : globalMargin
					};
					$scope.customersStats.push(customerStats);
					if(customerStats.count>0)
						$scope.activeCustomersStats.push(customerStats);
				});
				if ($scope.fundingsSum!==0)
					$scope.globalMargin = (1 - $scope.totalCost / $scope.fundingsSum) * 100;
				else
					$scope.globalMargin = 100;
			});
		});

		$log.debug('customers stats');
		$log.debug($scope.customersStats);

		jsonData.getQuotes().then(function(data){
			angular.forEach(data, function(quote, key){
			});
		});

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a href ui-sref="viewProds({ customerCode : row.entity.code, userId:\'\'})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var currencyCellTemplate = '<div class="ui-grid-cell-contents" title="">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		$scope.gridSettings = {
			data: $scope.activeCustomersStats,
			enableGridMenu: true,
			exporterExcelFilename: 'clients.xlsx',
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);

				// Setup events so we're notified when grid state changes.
				$scope.gridApi.core.on.columnVisibilityChanged($scope, saveState);
				$scope.gridApi.core.on.filterChanged($scope, saveState);
				$scope.gridApi.core.on.sortChanged($scope, saveState);
				$scope.gridApi.core.on.scrollEnd($scope, saveState);
			},
			enableCellSelection: false,
			enableRowSelection: true,
			enableSelectAll: true,
			multiSelect: true,
			enableFiltering: true,
			enableGrouping: false,
			enableColumnResizing: true,
			showColumnFooter: true,
			afterSelectionChange: sumSelectedRows,
			columnDefs : [
				{
					displayName : "Code",
					field : "code",
					enableSorting: true,
					width : 80,
					cellTemplate: linkCellTemplate,
					cellClass: "grid-align-center"
				}, {
					displayName : "Nom",
					field : "name",
					enableSorting: true,
					cellTemplate: linkCellTemplate,
					cellClass: "grid-align-left"
				},{
					displayName : "Dossiers",
					field : "count",
					width : 105,
					type: 'number',
					enableSorting: true,
					enableFiltering: false,
					aggregationHideLabel: true,
					footerCellClass : "grid-align-center",
					aggregationType: uiGridConstants.aggregationTypes.sum,
					cellClass: "grid-align-center"
				},{
					displayName : "CA",
					headerTooltip: 'Chiffre d\'affaire',
					field : "fundingsSum",
					visible : true,
					enableFiltering: false,
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					displayName : "Coûts",
					headerTooltip: 'Coûts externes (constatés pour les dossiers terminés et estimés pour les dossiers en cours)',
					field : "totalCost",
					visible : false,
					enableFiltering: false,
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					displayName : "Marge %",
					headerTooltip: 'Marge brute',
					field : "globalMargin",
					visible : true,
					cellFilter: "number:1",
					footerCellFilter: "number:2",
					enableFiltering: false,
					width : 110,
					type: 'number',
					aggregationType: getGlobalMargin,
					footerCellClass: "grid-align-center",
					cellClass: "grid-align-center"
				}, {
					displayName : "Marge brute",
					headerTooltip: 'Montant marge brute',
					field : "marginAmount",
					visible : false,
					enableFiltering: false,
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					displayName : "Engagé",
					headerTooltip: 'Devis engagés',
					field : "engagedQuotesSum",
					visible : true,
					enableFiltering: false,
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					field : "pipeSum",
					headerTooltip: 'Pipe commercial',
					visible : true,
					enableFiltering: false,
					displayName : "Pipe",
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					field : "canceledQuotesSum",
					headerTooltip: 'Dossiers annulés [prise en compte du devis le plus bas pour chaque dossier annulé ou du report]',
					visible : false,
					enableFiltering: false,
					displayName : "Annulé",
					width : 115,
					cellFilter: "currency",
					type: 'number',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					aggregationHideLabel: true,
					footerCellFilter: 'currency',
					cellClass: "grid-align-right",
					footerCellClass: "grid-align-right"
				},{
					displayName : "Transfo %",
					headerTooltip: 'Taux de transformation des dossiers [sur la base du montant]',
					field : "successRate",
					visible : false,
					cellFilter: "number:1",
					footerCellFilter: "number:2",
					enableFiltering: false,
					width : 110,
					type: 'number',
					aggregationType: getGlobalSuccessRate,
					footerCellClass: "grid-align-center",
					cellClass: "grid-align-center"
				}
			]
		};
		var state = localStorageService.get('customersGridState');
		if(state)
			$timeout(function(){restoreState(state);}, 500);

		function saveState() {
			var state = $scope.gridApi.saveState.save();
				localStorageService.set('customersGridState', state);
				$log.debug('Save customers grid state');
		}

		function restoreState(state) {
			if (state){
				$log.debug('Restore customers grid state');
				$scope.gridApi.saveState.restore($scope, state);
			}
		}
		function FilterByCustomerCode(production) {
			return production.customerCode == this.code;
		}

		function sumSelectedRows(rows){
		}

		$scope.resetGridData = function(){
			if($scope.activeCustomers){
				$scope.gridSettings.data = $scope.activeCustomersStats;
				$log.debug('reset customers grid data (active)');
			} else {
				$scope.gridSettings.data = $scope.customersStats;
				$log.debug('reset customers grid data (all)');
			}
			//$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ROW);
		};

		function getGlobalSuccessRate(rows){
			$log.debug('getGlobalSuccessRate');
			$log.debug(rows);
			var canceledFundings = 0;
			var fundings = 0;

			angular.forEach(rows, function(row, index){
				fundings += row.entity.fundingsSum;
				canceledFundings += row.entity.canceledQuotesSum;
			});
			$log.debug(fundings);
			$log.debug(canceledFundings);
			if (fundings)
				return (1-(canceledFundings/(fundings+canceledFundings)))*100;
			else
				return 0;
		}

		function getGlobalMargin(rows){
			$log.debug('getGlobalMargin');
			$log.debug(rows);
			var margin = 0;
			var costs = 0;
			var fundings = 0;

			angular.forEach(rows, function(row, index){
				costs += row.entity.totalCost;
				fundings += row.entity.fundingsSum;
			});
			if (fundings){
				margin = (1 - (costs / fundings)) * 100;
			}
			return margin;
		}
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditCustomerInvoiceLineCtrl',['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'jsonData', ModalEditCustomerInvoiceLineCtrl]);


	function ModalEditCustomerInvoiceLineCtrl($scope, $uibModalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;
		$scope.recipients = [];

		$scope.production = {
			'productionId' : 0,
			'customerCode' : '',
			'extraFundings' : 0,
			'subProdFunding' : 0,
			'carriedForwardFundings': 0,
			'index' : 0,
			'type'	: '',
			'code'	: '',
			'description' : '',
			'observation' : '',
			'status' : '0'
		};

		$http.get('/api/invoiceRecipients',{}).then(
			function(response) {
				$scope.recipients = response.data;
				$log.debug('Invoice Recipients');
				$log.debug($scope.recipients);
			},
			function(httpError){
				throw httpError.status + " : " + httpError.data;
			}
		);

		if($scope.params.productionCode){
			jsonData.getProduction($scope.params.productionCode).then(function(data){
				$scope.production={
					'productionId' : data.prodId,
					'customerCode' : data.customer.code,
					'index' : data.prodIndex,
					'type'	: data.prodType,
					'code'	: data.customer.code+String("000" + data.prodIndex).slice(-3),
					'description' : data.description,
					'observation' : data.observations,
					'status' : data.status.toString(),
					'PM'	 : data.userId,
					'extraFundings'		: data.extraFundings,
					'subProdFunding'	: data.subProdFunding,
					'partialClosure'	: data.partialClosure,
					'carriedForwardFundings'	: data.carriedForwardFundings
				};
			});
		}

		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
		});

		jsonData.getUsers().then(function(data){
			$scope.users=data;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		$scope.setProductionIndex = function(){
			$http.post('/json/getProductionIndex.php', {
				'customerCode' : $scope.production.customerCode,
			}).then(
				function(response) {
					//$scope.production.description="Got it !";
					$scope.production.index = response.data.nextIndex;
					$scope.production.code = response.data.productionCode;
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.getProductionCode = function(){
			return $scope.production.code+$scope.production.type;
		};

		$scope.ok = function() {
			$http.post('/json/setProduction.php', {
				'production' : $scope.production
			}).then(
				function(response) {
					$state.go('viewProduction', {"productionId" : response.data.production.code+response.data.production.type}, {reload: true});
					$uibModalInstance.close('ok');
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditEmployeeCtrl', ['$scope', '$uibModalInstance', '$log', '$http', '$state', '$filter', 'params', 'jsonData', modalEditEmployeeCtrl]);


	function modalEditEmployeeCtrl($scope, $uibModalInstance, $log, $http, $state, $filter, params, jsonData) {
		$scope.params = params;
		$scope.opened = false;
		$scope.dateOptions = {
			'startingDay': 1,
			'showWeeks': false,
			'maxDate': new Date(),
			'maxMode': 'year',
			'datepickerMode': 'year'
		};

		$scope.employee = {};
		$log.debug('modalEditEmployee params');
		$log.debug($scope.params);
		$scope.open = function() {
			$scope.opened = true;
		};

		$scope.ok = function() {
			$uibModalInstance.close($scope.wage);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditExpenseCtrl',['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'jsonData', modalEditExpenseCtrl]);


	function modalEditExpenseCtrl($scope, $uibModalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;
		$scope.userInterfaceEnabled = $scope.params.orderedAmount>0;

		$scope.expense = {
			'expenseId'			: params.expense ? params.expense.expenseId:undefined,
			'amount'			: '',
			'description'		: '',
			'date'				: new Date().setHours(12,0,0,0),
			'employee'			: undefined
		};

		if($scope.expense.expenseId){
			$http.post('json/getExpenses.php', {'expenseId' : $scope.expense.expenseId}).then(
				function(response) {
					$scope.expense.amount = response.data[0].amount;
					$scope.expense.description = response.data[0].description;
					$scope.expense.date = new Date(parseInt(params.expense.JSdate));
					$http.post('json/getEmployees.php', {'employeeId' : response.data[0].employeeId}).then(
						function(response) {
							$scope.expense.employee=response.data[0];
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		}

		$scope.setEmployee = function(item, employee, employeeName){
			$scope.expense.employee = employee;
		};

		$scope.employees = [];
		jsonData.getEmployees().then(function(data){
			$scope.employees = data;
		});

		$scope.ok = function() {
			$uibModalInstance.close($scope.expense);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditInvoiceCtrl',['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'dialogs', 'jsonData', modalEditInvoiceCtrl]);

	function modalEditInvoiceCtrl($scope, $uibModalInstance, $log, $http, $state, params, dialogs, jsonData) {
		$scope.params = params;
		$log.debug('modalEditInvoice params');
		$log.debug(params);
		$scope.today = null;
		$scope.opened = false;
		$scope.exchangeRates = {};
		$scope.userInterfaceEnabled = $scope.params.orderedAmount>0;

		$scope.invoice = {
			'id'				: 0,
			'date'				: new Date(),
			'amount' 			: 0,
			'supplier'			: undefined,
			'description'		: '',
			'number'			: ''
		};

		if (params.invoice){
			$scope.invoice = {
					'id'				: params.invoice.invoiceId,
					'date'				: new Date(parseInt(params.invoice.JSdate)),
					'amount' 			: params.invoice.amount,
					'supplier'			: params.invoice.supplier,
					'description'		: params.invoice.description,
					'number'			: params.invoice.number
			};
		}

		jsonData.getSuppliers({}).then(function(data){
			$scope.suppliers=data;
		});


		$scope.production={
			'productionId' : 0,
			'customerCode' : '',
			'extraFundings' : 0,
			'index' : 0,
			'type'	: '',
			'code'	: '',
			'description' : '',
			'observation' : '',
			'status' : 1
		};

		if($scope.params.productionCode){
			jsonData.getProduction($scope.params.productionCode).then(function(data){
				$scope.production={
					'productionId' : data.prodId,
					'customerCode' : data.customer.code,
					'index' : data.prodIndex,
					'type'	: data.prodType,
					'code'	: data.customer.code+String("000" + data.prodIndex).slice(-3),
					'description' : data.description,
					'observation' : data.observations,
					'status' : 1,
					'PM'	 : data.userId,
					'extraFundings' : data.extraFundings
				};
			});
		}

		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
		});

		jsonData.getUsers().then(function(data){
			$scope.users=data;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		$http.get('https://api.exchangeratesapi.io/latest', {}).then(
			function(response) {
				return($scope.exchangeRates = response.data);
			},
			function(httpError){
				throw httpError.status + " : " + httpError.data;
			}
		);

		$scope.getProductionCode = function(){
			return $scope.production.code+$scope.production.type;
		};

		$scope.dollarsToEuros = function(dollars) {
			if(angular.isDefined($scope.exchangeRates.rates) && dollars){
				return Math.round(dollars*100/$scope.exchangeRates.rates.USD)/100;
			} else {
				return 0;
			}
		};

		$scope.dollarsToEurosRate = function () {
			if(angular.isDefined($scope.exchangeRates.rates)) {
				return Math.round(1000/$scope.exchangeRates.rates.USD)/1000;
			} else {
				return 0;
			}
		};

		$scope.open = function() {
			$scope.opened = true;
		};

		$scope.dateOptions = {
			'startingDay': 1,
			'showWeeks': false,
			'maxDate': new Date(),
			'maxMode': 'day'
		};

		$scope.ok = function() {
			if (!angular.isObject($scope.invoice.supplier)&& !$scope.invoice.id){
				var dlg = dialogs.confirm('Facture', 'Créer le fournisseur : ' + $scope.invoice.supplier, {'size': 'sm'});
				dlg.result.then(function(btn){
					$uibModalInstance.close($scope.invoice);
				},function(btn){
				});
			} else {
				$uibModalInstance.close($scope.invoice);
			}
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();
(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditProjectCtrl',['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'jsonData', ModalEditProjectCtrl]);


	function ModalEditProjectCtrl($scope, $uibModalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;

		$scope.production = {
			'productionId' : 0,
			'customerCode' : '',
			'extraFundings' : 0,
			'subProdFunding' : 0,
			'carriedForwardFundings': 0,
			'index' : 0,
			'type'	: '',
			'code'	: '',
			'description' : '',
			'observation' : '',
			'status' : '0'
		};

		if($scope.params.productionCode){
			jsonData.getProduction($scope.params.productionCode).then(function(data){
				$scope.production={
					'productionId' : data.prodId,
					'customerCode' : data.customer.code,
					'index' : data.prodIndex,
					'type'	: data.prodType,
					'code'	: data.customer.code+String("000" + data.prodIndex).slice(-3),
					'description' : data.description,
					'observation' : data.observations,
					'status' : data.status.toString(),
					'PM'	 : data.userId,
					'extraFundings'		: data.extraFundings,
					'subProdFunding'	: data.subProdFunding,
					'partialClosure'	: data.partialClosure,
					'carriedForwardFundings'	: data.carriedForwardFundings
				};
			});
		}

		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
		});

		jsonData.getUsers().then(function(data){
			$scope.users=data;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		$scope.setProductionIndex = function(){
			$http.post('/json/getProductionIndex.php', {
				'customerCode' : $scope.production.customerCode,
			}).then(
				function(response) {
					//$scope.production.description="Got it !";
					$scope.production.index = response.data.nextIndex;
					$scope.production.code = response.data.productionCode;
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.getProductionCode = function(){
			return $scope.production.code+$scope.production.type;
		};

		$scope.ok = function() {
			$http.post('/json/setProduction.php', {
				'production' : $scope.production
			}).then(
				function(response) {
					$state.go('viewProduction', {"productionId" : response.data.production.code+response.data.production.type}, {reload: true});
					$uibModalInstance.close('ok');
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditQuoteCtrl', ['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'jsonData', ModalEditQuoteCtrl]);

	function ModalEditQuoteCtrl($scope, $uibModalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;
		$scope.opened = false;
		$scope.quote={
			'quoteId'			: 0,
			'prodId'			: 0,
			'quoteNumber'		: '',
			'amount'			: 0.0,
			'customerRecipient'	: '',
			'description'		: '',
			'observations'		: '',
			'status'			: '0',
			'sourceQuoteId'		: '0',
			'sourceQuoteNumber'	: '',
			'cancelSourceQuote'	: 0,
			'deliveryDate'		: null
		};
		$scope.quotes =[];

		if($scope.params.quoteNumber){
			jsonData.getQuotes({'quoteNumber' :$scope.params.quoteNumber}).then(function(data){
				$log.debug('quotes');
				$log.debug(data);
				$scope.quote={
					'quoteId'			: parseInt(data[0].quoteId),
					'prodId'			: parseInt(data[0].productionId),
					'quoteNumber'		: data[0].quoteNumber,
					'amount'			: parseFloat(data[0].amount),
					'index'				: data[0].rank,
					'status'			: data[0].status,
					'customerRecipient'	: data[0].customerRecipient,
					'description'		: data[0].description,
					'observations'		: data[0].observations,
					'deliveryDate'		: data[0].JSdeliveryDate===null ? null : new Date(parseInt(data[0].JSdeliveryDate))
				};
				if(data[0].JSdeliveryDate===null)
					$scope.dateOptions.minDate = new Date();
				else
					$scope.dateOptions.minDate = new Date(parseInt(data[0].JSdeliveryDate));
			});
		} else {
			//get next quote number
			$http.post('/json/getQuoteNumber.php', {
				'productionCode' : $scope.params.productionCode
			}).then(
				function(response){
					$scope.quote.quoteNumber = response.data.quoteNumber;
					$scope.quote.index = response.data.nextIndex;
					$scope.quote.description = $scope.params.productionDescription;
					$scope.getProductionsQuotes();
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		}

		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
		});

		jsonData.getUsers().then(function(data){
			$scope.users=data;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		$scope.getProductionsQuotes = function(){
			jsonData.getQuotes({'productionCode': $scope.params.productionCode}).then(function(data){
				$scope.quotes = data;
				$log.debug(data);
			});
		};

		$scope.setSourceQuote = function(){
			if ($scope.quote.sourceQuoteId=='0'){
				$scope.quote.description = $scope.params.productionDescription;
				$scope.quote.customerRecipient ='';
				$scope.quote.observations = '';
			} else {
				angular.forEach($scope.quotes, function(quote, index){
					if(quote.quoteId==$scope.quote.sourceQuoteId){
						$scope.quote.description = quote.description;
						$scope.quote.customerRecipient = quote.customerRecipient;
						$scope.quote.sourceQuoteNumber = quote.quoteNumber;
						if ($scope.quote.cancelSourceQuote=='1')
							$scope.quote.observations = sprintf("Annule et remplace %s", quote.quoteNumber);
						else
							$scope.quote.observations = '';
					}
				});
			}
		};

		$scope.open = function() {
			$scope.opened = true;
		};


		$scope.dateOptions = {
			'startingDay': 1,
			'showWeeks': false,
			'maxMode': 'day'
		};

		$scope.ok = function() {
			$uibModalInstance.close($scope.quote);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditQuotesCtrl', ['$scope', '$uibModalInstance', '$log', '$http', '$state', 'params', 'jsonData', ModalEditQuotesCtrl]);

	function ModalEditQuotesCtrl($scope, $uibModalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;
		$scope.project = params.project;
		$scope.opened = false;
		$scope.quotes = [];

		$log.debug(params);

		angular.forEach(params.quotes, function(quote){
			if (quote.status<4)
				$scope.quotes.push(quote);
		});

		$scope.$watchCollection('quotes', function(){
			$log.debug('quote status changed');
		});

		$log.debug($scope.quotes);
		$scope.ok = function() {
			$uibModalInstance.close($scope.quotes);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};

		$scope.updateQuote = function(quote){
			$log.debug('updateQuote');
			$http.post('/json/setQuote.php', {
				'quoteId'			: quote.quoteId,
				'index'				: quote.index,
				'quoteNumber'		: quote.quoteNumber,
				'productionCode'	: $scope.params.productionCode,
				'customerRecipient'	: quote.customerRecipient,
				'description'		: quote.description,
				'deliveryDate' 		: quote.deliveryDate,
				'observations'		: quote.observations,
				'customerCode'		: $scope.params.customerCode,
				'customerName'		: $scope.params.customerName,
				'status'			: quote.status
			}).then(
				function(response) {
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalEditWageCtrl', ['$scope', '$uibModalInstance', '$log', '$http', '$state', '$filter', 'params', 'jsonData', modalEditWageCtrl]);


	function modalEditWageCtrl($scope, $uibModalInstance, $log, $http, $state, $filter, params, jsonData) {
		$scope.params = params;
		$scope.periodDate = new Date();
		$scope.employeeWorkPeriods = [];
		$scope.periodCost = 0.0;
		$scope.taxRate = 62;
		$scope.periodDuration = '0';
		$scope.userInterfaceEnabled = $scope.params.orderedAmount>0;
		$scope.wage = {
			'wageId'			: params.wage ? params.wage.wageId:undefined,
			'declarationMonth'	: $scope.periodDate.getMonth().toString(),
			'totalCost'			: '',
			'employee'			: undefined,
			'periods'			: []
		};

		if($scope.wage.wageId){
			$http.post('json/getWages.php', {'wageId' : $scope.wage.wageId})
				.then(
					function(response) {
						$scope.wage.totalCost = response.data[0].amount;
						$scope.wage.declarationMonth = response.data[0].declarationMonth;
						$http.post('json/getEmployees.php', {'employeeId' : response.data[0].employeeId}).then(
							function(response) {
								$scope.wage.employee=response.data[0];
								$http.post('json/getEmployeePeriods.php', {'wageId' : $scope.wage.wageId}).then(
									function(response) {
										jsonData.getWorkPeriods($scope.wage.employee.employeeId).then(
											function(data){
												$scope.employeeWorkPeriods = data;
											},
											function(httpError){
												throw httpError.status + " : " + httpError.data;
											}
										);
										angular.forEach(response.data, function(workedDay, key){
											$scope.wage.periods.push({
												'duration'	: workedDay.duration,
												'date'		: workedDay.periodDate,
												'cost'		: parseFloat(workedDay.cost)
											});
										});
									},
									function(httpError){
										throw httpError.status + " : " + httpError.data;
									}
								);
							},
							function(httpError){
								throw httpError.status + " : " + httpError.data;
							}
						);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
		}

		$scope.addPeriod = function(){
			$scope.periodDate.setHours(12,0,0,0);
			if($scope.validatePeriod($scope.periodDate.getTime())){
				$scope.wage.periods.push({
					'duration'	: $scope.periodDuration,
					'date'		: $scope.periodDate.getTime(),
					'cost'		: parseFloat($scope.periodCost)
				});
				$scope.getTotalCost();
			}
		};

		$scope.setEmployee = function(item, employee, employeeName){
			$scope.wage.employee = employee;
			$scope.setCost();
			jsonData.getWorkPeriods(employee.employeeId).then(function(data){
				$scope.employeeWorkPeriods = data;
			});
		};

		$scope.clearStatus = function(){
			$scope.statusMessage = "";
		};

		$scope.setCost = function(){
			if(parseInt($scope.periodDuration)>=8)
				$scope.periodCost = $scope.wage.employee.priceRate;
			else
				$scope.periodCost = parseFloat($scope.wage.employee.priceRate) / 8 * parseInt($scope.periodDuration);
		};

		$scope.removePeriod = function(removedPeriod) {
			var indexToRemove;
			angular.forEach($scope.wage.periods, function(period, key){
				if (period.date==removedPeriod.date)
					indexToRemove = key;
			});
			if (typeof indexToRemove != 'undefined'){
				$scope.wage.periods.splice(indexToRemove, 1);
				$scope.getTotalCost();
			}
		};

		$scope.getTotalCost = function(){
			var totalCost = 0.0;
			angular.forEach($scope.wage.periods, function(period, key){
				totalCost += parseFloat(period.cost);
			});
			totalCost*=1+($scope.taxRate/100);
			$scope.wage.totalCost = totalCost;
		};

		$scope.validatePeriod = function(periodDate){
			var localFound = $filter('filter')($scope.wage.periods, {date: periodDate}, true);
			if (localFound.length) {
				$scope.statusMessage="Date invalide";
				return false;
			} else {
				var globalFound = $filter('filter')($scope.employeeWorkPeriods, {periodDate: periodDate.toString()}, true);
				if (globalFound.length) {
					if(parseInt(globalFound[0].duration) + parseInt($scope.periodDuration)<=10)
						return true;
					else {
						$scope.statusMessage="Date en conflit "+globalFound[0].description+" ["+globalFound[0].code+"]";
						return false;
					}
				} else
					return true;
			}
		};

		$scope.employees = [];
		jsonData.getEmployees().then(function(data){
			$scope.employees = data;
		});

		jsonData.getCustomers().then(function(data){
			$scope.customers=data;
		});

		jsonData.getUsers().then(function(data){
			$scope.users=data;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		$scope.ok = function() {
			$uibModalInstance.close($scope.wage);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalParseResultsCtrl', ['$scope', '$modalInstance', '$log', '$http', '$state', 'params', 'jsonData', modalParseResultsCtrl]);

	function modalParseResultsCtrl($scope, $modalInstance, $log, $http, $state, params, jsonData) {
		$scope.params = params;

		$log.debug(params);

		$scope.ok = function() {
			$modalInstance.close('yes');
		};

		$scope.cancel = function() {
			$modalInstance.dismiss('no');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('modalViewMultimagesProjects', ['$scope', '$uibModalInstance', '$log', '$http', '$state', '$filter', 'params', 'jsonData', modalViewMultimagesProjects]);


	function modalViewMultimagesProjects($scope, $uibModalInstance, $log, $http, $state, $filter, params, jsonData) {
		$scope.params = params;
		$log.debug(params);

		$scope.ok = function() {
			$uibModalInstance.close($scope.wage);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss('cancel');
		};
	}
})();


(function() {
	'use strict';

	angular
		.module('main')
		.controller('searchProductionCtrl', ['$scope', '$rootScope', '$stateParams', '$log', '$http', '$location', 'jsonData', searchProductionCtrl]);

	function searchProductionCtrl($scope, $rootScope, $stateParams, $log, $http, $location, jsonData) {
		// Properties
		$scope.results = [];
		$scope.separators = {'2015': '#', '2016': '#', '2017': '#!', '2018': '#!', '2019': '#!'};
		$scope.year = parseInt($stateParams.year);

		// *********************************
		// Controller Init
		// *********************************

		$http.get(sprintf('/api/productions/search/%s/%s', $stateParams.year, $stateParams.query)).then(
			function(response) {
				$log.debug(response.data);
				$scope.results=response.data;
			},
			function(httpError){
				throw httpError.status + " : " + httpError.data;
			}
		);

		// *********************************
		// Controller methods
		// *********************************

		// *********************************
		// Internal methods
		// *********************************

		function tmpfunc(){

		}

		// *********************************
		// Events handlers
		// *********************************

	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewDeclarationPeriodsCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', 'dialogs', 'Notification', 'jsonData', viewDeclarationPeriodsCtrl]);


	function viewDeclarationPeriodsCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, dialogs, Notification, jsonData) {
		$scope.declarationLines = [];
		$scope.declarationMonth	= new Date().getMonth().toString();
		$scope.currentDeclarationMonth	= new Date().getMonth().toString();

		$scope.getPeriods = function(){
			$http.post('json/getPeriods.php', {'declarationMonth' : $scope.declarationMonth})
				.then(
					function(response) {
						$scope.declarationLines = response.data;
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
		};

		$scope.setFilterText = function(){
			filterValues = [];
			angular.forEach($scope.filterValues, function(value, key){
				if(value.length){
					filterValues.push(key+":"+value);
				}
			});
			$scope.filterOptions.filterText = filterValues.join(';');
		};

		$scope.notify = function(){
			var dlg = dialogs.confirm('Notification', 'Envoi d\'un récapitulatif au intermittents pour le mois en cours', {'size' : 'sm'});
			dlg.result.then(
				function(btn){
					$log.debug('Notification confirmed');
					$http.post('/json/notifyEmployees.php', {
							'declarationMonth'	: $scope.declarationMonth,
							'declarationLines'	: $scope.declarationLines
						}).then(
							function(response) {
								var notificationMessage = sprintf('Notification<br><ul><li>A envoyer: %d</li><li>Envoyées: %d</li><ul>', response.data.mailCount, response.data.mailSent);
								if(response.data.mailCount == response.data.mailSent)
									Notification.success({'message':notificationMessage, 'delay': 10000});
								else{
									notificationMessage += sprintf('<strong>Erreur: %s</strong>', response.data.errors);
									Notification.error({'message':notificationMessage, 'delay': 10000});
								}
								$log.debug(response);
							},
							function(httpError){
								$log.error(httpError);
							}
						);
				},
				function(btn){
					$log.debug('No confirmation');
				});
		};


		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-danger\': row.entity.status==\'2\', \'bg-success\': row.entity.status==\'1\', \'bg-warning\': row.entity.pendingQuotesSum>0}"> <a href ui-sref="viewProduction({ productionId : row.entity.code})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var htmlContentTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP"><span ng-bind-html="grid.getCellValue(row, col)"></span></div>';

		$scope.gridSettings = {
			data : "declarationLines",
			enableCellSelection: false,
			enableFiltering: true,
			showColumnFooter: true,
	        enableColumnMenus: false,
			columnDefs : [{
				field : "fullName",
				enableSorting: true,
				width : "150",
				displayName : "Nom",
				cellClass: "grid-align-left"
			}, {
				field : 'email',
				enableSorting: true,
				width : "180",
				displayName : "Email",
				cellClass: "grid-align-left"
			},{
				field : "code",
				enableSorting: false,
				width : "110",
				displayName : "Dossier",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "description",
				enableSorting: false,
				displayName : "Description",
				cellClass: "grid-align-left"
			}, {
				field : "periodsList",
				width: '30%',
				enableSorting: false,
				displayName: "Périodes",
				cellTemplate: htmlContentTemplate,
				cellClass: "grid-align-left"
			}, {
				field : "totalCost",
				enableSorting: false,
				displayName : "Total brut",
				width : "110",
				cellFilter: "currency",
				cellClass: "grid-align-center",
				enableFiltering: false,
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right"
			}]
		};

		$scope.getPeriods();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewEmployeesCtrl',['$scope', '$rootScope', '$uibModal', 'uiGridConstants','$log', '$http', '$timeout', 'jsonData', viewEmployeesCtrl]);

	function viewEmployeesCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, jsonData) {
		$scope.employeesArray = [];

		$scope.getEmployees = function(){
			jsonData.getEmployeesTotals().then(function(data){
				$scope.employees = data;
				angular.forEach($scope.employees, function(employee, key){
					$scope.employeesArray.push({
						'employeeId'	: employee.employeeId,
						'name'			: employee.firstName+' '+employee.name,
						'qualification'	: employee.qualification,
						'phone'			: employee.phone,
						'email'			: employee.email,
						'expensesSum'	: employee.expensesSum,
						'wagesSum'		: employee.wagesSum,
						'priceRate'		: employee.priceRate
					});
				});
			});
		};

		$scope.setFilterText = function(){
			filterValues = [];
			angular.forEach($scope.filterValues, function(value, key){
				if(value.length){
					filterValues.push(key+":"+value);
				}
			});
			$scope.filterOptions.filterText = filterValues.join(';');
			$log.debug(filterValues.join(';'));
		};

		$scope.editEmployee = function(row){
			var modalInstance = $scope.openModal('./partials/modalEditEmployee.html', 'modalEditEmployeeCtrl', {'employeeRow' : row}, 'lg');
		};

		var actionLinks = '<div class="ui-grid-cell-contents" title="TOOLTIP"><span class="link text-info glyphicon glyphicon-edit" aria-hidden="true" ng-click="grid.appScope.editEmployee(row.entity)"></span></div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'employees.xlsx',
			exporterSuppressColumns: [ 'employeeId' ],
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			data : "employeesArray",
			enableCellSelection: false,
			enableRowSelection: true,
			enableFiltering: true,
			showColumnFooter: true,
			columnDefs : [{
				field : "name",
				sortable: false,
				width : "250",
				displayName : "Nom",
				cellClass: "grid-align-left"
			}, {
				field : "qualification",
				sortable: false,
				displayName : "Qualification",
				cellClass: "grid-align-left"
			}, {
				field : "phone",
				sortable: false,
				enableFiltering: false,
				displayName : "Téléphone",
				width : "150",
				cellClass: "grid-align-center"
				}, {
				field : "email",
				sortable: false,
				enableFiltering: true,
				displayName : "email",
				width : "200",
				cellClass: "grid-align-center"
				}, {
				field : "priceRate",
				displayName : "Prix / jour",
				type: 'number',
				sortable: false,
				enableFiltering: false,
				cellFilter: "currency",
				width : "150",
				cellClass: "grid-align-center"
			}, {
				field : "expensesSum",
				displayName : "Frais",
				type: 'number',
				cellFilter: "currency",
				sortable: false,
				enableFiltering: false,
				width : "150",
				cellClass: "grid-align-center",
				footerCellFilter: 'currency',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
			}, {
				field : "wagesSum",
				displayName : "Salaires brut",
				width : "150",
				cellFilter: "currency",
				enableFiltering: false,
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
				cellClass: "grid-align-right"
			}, {
				field : "employeeId",
				cellTemplate: actionLinks,
				sortable: false,
				enableFiltering: false,
				displayName : "",
				width : 70,
				cellClass: "grid-align-center"
			}]
		};

		$scope.getEmployees();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewExpensesCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', '$state', 'jsonData', 'localStorageService', viewExpensesCtrl]);

	function viewExpensesCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, $state, jsonData, localStorageService) {
		$scope.rowsTotalAmount=0;

		$scope.getExpenses = function(){
			jsonData.getExpenses({}).then(function(expenses){
				var expensesArray = [];
				angular.forEach(expenses, function(expense, key){
					expensesArray.push({
						'expensesId'		: expense.expenseId,
						'productionCode'	: expense.productionCode,
						'employeeId'		: expense.employeeId,
						'employeeFullName'	: expense.employeeFullName,
						'date'				: expense.JSdate,
						'amount'			: parseFloat(expense.amount),
						'description'		: expense.description
					});
				});
				$scope.gridSettings.data = expensesArray;
			});
		};

		function sumSelectedRows(){
			$scope.rowsTotalAmount=0;
			angular.forEach($scope.gridApi.selection.getSelectedRows(), function(row, index){
				$scope.rowsTotalAmount += row.amount;
			});
		}

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-warning\': row.entity.status==\'1\', \'bg-info\': row.entity.status==\'2\', \'bg-success\': row.entity.status==\'3\', \'bg-danger\': row.entity.status==\'4\'}"><a href ui-sref="viewProductionTab({ productionId : row.entity.productionCode, tabNumber: 5})">{{COL_FIELD CUSTOM_FILTERS}}</a></div>';


		$scope.gridSettings = {
			enableCellSelection: false,
			enableRowSelection: true,
			enableFiltering: true,
			showColumnFooter: true,
			headerRowHeight: 43,
			showSelectionCheckbox: true,
			multiSelect: true,
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);
			},
			columnDefs : [{
				field : "productionCode",
				sortable: false,
				width : 100,
				displayName : "Code",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "date",
				width : 100,
				sortable: true,
				cellFilter: "date : 'shortDate'",
				cellClass: "grid-align-center",
				displayName : "Date"
			}, {
			field : "employeeFullName",
				sortable: false,
				width : 180,
				displayName : "Nom"
			}, {
				field : "description",
				sortable: false,
				displayName : "Description"
			}, {
				field : "amount",
				width : 130,
				displayName : "Montant",
				cellFilter: "currency",
				type: "number",
				footerCellFilter: 'currency',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
				cellClass: "grid-align-right"
			}]
		};

		$scope.getExpenses();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewInvoicesCtrl', ['$scope', '$rootScope', 'uiGridConstants', '$stateParams','$log', '$http', '$filter', 'jsonData', ViewInvoicesCtrl]);

	function ViewInvoicesCtrl($scope, $rootScope, uiGridConstants, $stateParams, $log, $http, $filter, jsonData) {

		$scope.getInvoices = function(){
			jsonData.getInvoices().then(function(invoices){
				var invoicesArray = [];
				angular.forEach(invoices, function(invoice, key){
					invoicesArray.push({
						'invoiceId'			: invoice.invoiceId,
						'productionCode'	: invoice.productionCode,
						'date' 				: invoice.date,
						'JSdate' 			: invoice.JSdate,
						'amount'			: parseFloat(invoice.amount),
						'number'			: invoice.number,
						'supplier'			: invoice.supplier,
						'description'		: invoice.description
					});
				});
				$scope.gridSettings.data = invoicesArray;
			});
		};

		$scope.editInvoice = function(row){
			var modalInstance = $scope.openModal('./partials/modalEditInvoice.html', 'modalEditInvoiceCtrl', {'productionCode' : row.productionCode, 'invoice' : row}, '');

			modalInstance.result.then(
				function (invoiceFromModal) {
					$http.post('/json/setInvoice.php', {
						'invoiceId'			: row.invoiceId,
						'productionCode'	: row.productionCode,
						'amount' 			: invoiceFromModal.amount,
						'date' 				: $filter('date')(invoiceFromModal.date, 'yyyy-MM-dd'),
						'supplier' 			: angular.isObject(invoiceFromModal.supplier)? invoiceFromModal.supplier.description : invoiceFromModal.supplier,
						'number'			: invoiceFromModal.number,
						'description'		: invoiceFromModal.description
					}).then(
						function(response) {
							$scope.getInvoices();
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				}
			);
		};

		function sumSelectedRows(){
			$scope.rowsTotalAmount=0;
			angular.forEach($scope.gridApi.selection.getSelectedRows(), function(row, index){
				$scope.rowsTotalAmount += row.amount;
			});
		}

		var linkCellTemplate = '<div class="ui-grid-cell-contents" ng-class="{\'bg-danger\': row.entity.status==\'2\', \'bg-success\': row.entity.status==\'1\', \'bg-warning\': row.entity.pendingQuotesSum>0}"> <a href ui-sref="viewProductionTab({ productionId : row.entity.productionCode, tabNumber: 3})">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var actionLinks = '<div class="ui-grid-cell-contents"><span class="link text-info glyphicon glyphicon-edit" aria-hidden="true" ng-click="grid.appScope.editInvoice(row.entity)"></span></div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'invoices.xlsx',
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
    		enableRowSelection: true,
    		enableSelectAll: true,
    		enableFiltering: true,
			showColumnFooter: true,
			multiSelect: true,
			//headerRowHeight: 43,
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);
			},
			columnDefs : [{
				field : "productionCode",
				sortable: false,
				width : 100,
				displayName : "Code",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "JSdate",
				sortable: true,
				cellClass: "grid-align-center",
				cellFilter: "date: 'shortDate'",
				displayName : "Date"
			}, {
				field : "number",
				sortable: false,
				displayName : "Numéro"
			}, {
				field : "supplier",
				displayName : "Fournisseur",
				width : "25%",
				sortable: false,
				cellClass: "grid-align-left",
				filter: {
					term : $stateParams.supplier
				}
			}, {
				field : "description",
				sortable: false,
				width : "35%",
				displayName : "Description",
				cellClass: "grid-align-left"
			}, {
				field : "amount",
				displayName : "Montant",
				width : 100,
				cellFilter: "currency",
				type: 'number',
				enableFiltering: true,
				footerCellFilter: 'currency',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
				cellClass: "grid-align-right"
			}, {
				field : "invoiceId",
				cellTemplate: actionLinks,
				enableColumnMenu: false,
				displayName : "Actions",
				enableSorting: false,
				enableFiltering: false,
				width : 70,
				cellClass: "grid-align-center"
			}]
		};

		$scope.getInvoices();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewOrdersCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', '$filter','$stateParams', '$window', 'localStorageService', 'jsonData', viewOrdersCtrl]);

	function viewOrdersCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, $filer, $stateParams, $window, localStorageService, jsonData) {
		//$log.debug(sprintf('Window innerHeight %s', $window.innerHeight));

		$scope.customerCode = $stateParams.customerCode;
		$scope.statusFilter = localStorageService.get('ordersStatusFilters') ? localStorageService.get('ordersStatusFilters') : {orderWait: true, invoiceWait: true};
		if(!$stateParams.userId){
			$scope.gridFilters = localStorageService.get('ordersFilters') ? localStorageService.get('ordersFilters') : {prodType: '', PM: '', customerCode: ''};
		}
		else
			$scope.gridFilters={prodType: '', PM: $stateParams.userId, customerCode: ''};
		$scope.productionsCount = {'opened':0, 'current':0, 'closed':0, 'canceled':0};
		$scope.rowsSelection = [];

		$scope.getProductions = function(){
			jsonData.getProductions($stateParams.customerCode).then(function(data){
				var state = localStorageService.get('ordersGridState');
				$scope.productions = data;
				$scope.filterDatas();
				if(state)
					$timeout(function(){restoreState(state);}, 500);
				$log.debug('getProductions');
				$log.debug($scope.productionsArray);
			});
		};

		jsonData.getUsers().then(function(users){
			$scope.users = users;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		jsonData.getActiveCustomers().then(function(customers){
			$scope.customers = customers;
		});

		$scope.setProductionsStatusFilter = function(productionStatus){
			$scope.filterDatas();
		};

		$scope.filterDatas = function (){
			localStorageService.set('ordersStatusFilters', $scope.statusFilter);
			localStorageService.set('ordersFilters', $scope.gridFilters);
			var productionsArray = [];
			$scope.productionsCount = {'opened':0, 'current':0, 'closed':0, 'canceled':0};

			angular.forEach($scope.productions, function(production, key){
				if((production.invoicesSum + production.wagesSum + production.expensesSum) && (production.status < 4)) {
					$log.debug(production.invoicesSum + production.wagesSum + production.expensesSum +  '€');
					if(FilterProduction(production)){
						var accountedCost = production.invoicesSum + production.expensesSum;
						var totalCost = production.invoicesSum + production.wagesSum + production.expensesSum;
						var currentTotalCost = production.invoicesCurrentSum + production.wagesCurrentSum + production.expensesCurrentSum;
						var date = new Date(production.date.substring(0, 10));
						var updateDate = new Date(production.updateDate.substring(0, 10));
						var finalTotalCost = 0;
						var finalGrossMargin = -Infinity;
						var currentGrossMargin = -Infinity;
						var grossMarginAmount = 0;
						var pipeFundingSum = 0;
						var fundingsSum = 0;

						switch(production.status){
							case 0: // Prod open
								pipeFundingSum = production.pendingQuotesSum + production.extraFundings + production.partialClosure - production.carriedForwardFundings;
								finalTotalCost = totalCost;
								$scope.productionsCount.opened++;
								break;

							case 1: // Prod in production
							case 2: // Prod is finished
								finalTotalCost = parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
								//totalCost= currentTotalCost;
								pipeFundingSum = production.pendingQuotesSum;
								fundingsSum = production.quotesSum + production.extraFundings + production.subProdFunding - production.carriedForwardFundings + production.partialClosure;
								$scope.productionsCount.current++;
								break;

							case 3: // prod clotured
								finalTotalCost = totalCost;
								fundingsSum = production.quotesSum + production.extraFundings + production.subProdFunding - production.carriedForwardFundings + production.partialClosure;
								$scope.productionsCount.closed++;
								break;

							case 4: // prod cancelled
								finalTotalCost = totalCost;
								pipeFundingSum = production.canceledFundings;
								$scope.productionsCount.canceled++;
								break;
						}

						if(production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings > 0){
							switch (parseInt(production.status)){
								case 0: // Prod open
									finalGrossMargin = Infinity;
									currentGrossMargin = -Infinity;
									grossMarginAmount = -totalCost;
									break;

								case 1: // Prod in production
								case 2: // Prod is finished
									finalGrossMargin = (1 - (finalTotalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings))) * 100;

									currentGrossMargin = (1 - (totalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure- production.carriedForwardFundings))) * 100;

									grossMarginAmount = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings - finalTotalCost;
									break;

								case 3: // Prod is clotured
									finalGrossMargin = (1 - (totalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings))) * 100;
									currentGrossMargin = finalGrossMargin;
									grossMarginAmount = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings - totalCost;
									break;

								case 4: // prod cancelled
									grossMarginAmount = -totalCost;
									break;
							}
						} else {
							finalGrossMargin = Infinity;
							currentGrossMargin = -Infinity;
							grossMarginAmount = -totalCost;
						}

						date = new Date(production.date.substring(0, 10));
						productionsArray.push({
							'code' : production.code,
							'customerCode' : production.customerCode,
							'observations' : production.observations,
							'extraFundings' : production.extraFundings,
							'subProdFunding' : production.subProdFunding,
							'carriedForwardFundings': production.carriedForwardFundings,
							'partialClosure': production.partialClosure,
							'transparent': production.transparent,
							'PM' : production.PM,
							'date' : date,
							'updateDate' : updateDate,
							'status' : production.status,
							'description' : production.description,
							'fundingsSum' : fundingsSum,
							'quotesSum'	: production.quotesSum,
							'pendingQuotesSum' : production.pendingQuotesSum,
							'pipeFundingSum': pipeFundingSum,
							'accountedCost': accountedCost,
							'expensesSum':  production.expensesSum,
							'invoicesSum': production.invoicesSum,
							'finalTotalCost': finalTotalCost,
							'totalCost' : totalCost,
							'estimatedFundings' : production.estimatedFundings,
							'totalWages' : production.wagesSum,
							'marginAmount' : grossMarginAmount,
							'estimatedMargin' : finalGrossMargin,
							'currentGrossMargin': currentGrossMargin,
							'currentMargin' : currentGrossMargin,
							'fileName' : production.fileName,
							'invoicesToCheck' : production.invoicesCheckedCountMax-production.invoicesCheckedCount
						});
					}
				}
			});
			$log.debug('Productions Count');
			$log.debug($scope.productionsCount);

			$scope.gridSettings.data = productionsArray;
			$log.debug(sprintf('Productions filtered : %s', $scope.gridSettings.data.length));
		};

		$scope.downloadFile = function(fileName, customerCode){
			$http.post('/json/downloadFile.php', {
				'fileName': fileName,
				'customerCode': customerCode
			});
		};

		$scope.setGridFilter = function(filter, value){
			$scope.gridFilters[filter]=value;
			$scope.filterDatas();
		};

		$scope.resetGridFilters = function(){
			angular.forEach($scope.gridFilters, function(gridFilter, key){
				$scope.gridFilters[key]='';
			});
			$scope.filterDatas();
		};

		$scope.setColumnVisibility = function (fieldName, visibility){
			angular.forEach($scope.gridSettings.columnDefs, function(columnDef, key){
				if(columnDef.field==fieldName){
					columnDef.visible = visibility;
				}
			});
		};

		function FilterProduction(production){
			var isSelected = true;
			angular.forEach($scope.gridFilters, function(gridFilter, key){
				if(gridFilter && production[key]!=gridFilter)
					isSelected = false;
			});
			return isSelected;
		}

		function sumSelectedRows(){
			saveState();
			$scope.rowsTotalFundings=0;
			$scope.rowsTotalCost=0;
			$scope.rowsSelection = $scope.gridApi.selection.getSelectedRows();
			angular.forEach($scope.rowsSelection, function(row, index){
				if((row.status<3 || $scope.statusFilter==='closed') && row.transparent === 0){
					$scope.rowsTotalFundings += row.fundingsSum;
					$scope.rowsTotalCost += row.finalTotalCost;
				}
			});
			if($scope.rowsTotalFundings!==0)
				$scope.rowsMargin = (1 - ($scope.rowsTotalCost / $scope.rowsTotalFundings )) *100;
			else
				$scope.rowsMargin = 100;
			$scope.rowsMarginAmount = $scope.rowsTotalFundings - $scope.rowsTotalCost;
		}

		function sumProjectsFundings(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.status<4 || $scope.statusFilter==='closed' && row.entity.transparent)
					sum += row.entity.fundingsSum-row.entity.subProdFunding;
			});
			return sum;
		}

		function sumProjectsPipe(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.status<4 || $scope.statusFilter==='closed')
					sum += row.entity.pendingQuotesSum;
			});
			return sum;
		}

		function sumProjectsCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.status>0 && row.entity.status<4 && row.entity.transparent)
					sum += row.entity.totalCost;
			});
			return sum;
		}

		function sumProjectsGrossMargin(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					if(row.entity.status>0 && row.entity.status<4)
						sum += row.entity.fundingsSum;
					if(row.entity.status===0 || row.entity.status===4)
						sum -=row.entity.accountedCost+row.entity.totalWages;
					else
						sum -=row.entity.finalTotalCost;
				}
			});
			return sum;
		}

		function sumProjectsFinalCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.finalTotalCost;
				}
			});
			return sum;
		}

		function sumProjectsAccountedCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.totalCost;
				}
			});
			return sum;
		}
		function getGlobalMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					switch(row.entity.status){
						case 2:
						case 3:
							rowsTotalCost += row.entity.totalCost;
							break;
						case 0:
						case 1:
							rowsTotalCost += row.entity.finalTotalCost;
							break;
					}
				}
			});
			//return (1 - rowsTotalCost / (rowsTotalFundings)) * 100;
			return ((1 - rowsTotalCost / (sumProjectsFundings(rows))) * 100);
		}

		function saveState() {
			var state = $scope.gridApi.saveState.save();
				localStorageService.set('ordersGridState', state);
				$log.debug('Save orders grid state');
		}

		function restoreState(state) {
			if (state){
				$log.debug('Restore orders grid state');
				$scope.gridApi.saveState.restore($scope, state);
			}
		}

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a href ui-sref="viewProduction({ productionId : row.entity.code})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var fundingCellTemplate = '<div class="ui-grid-cell-contents" title="Vente: {{row.entity.quotesSum | currency}}&#10;Report: {{row.entity.extraFundings | currency}}&#10;Projet principal: {{row.entity.subProdFunding | currency}}&#10;Devis en attente: {{row.entity.pendingQuotesSum | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var accountedCellTemplate = '<div class="ui-grid-cell-contents" title="Factures saisies: {{row.entity.invoicesSum | currency}}&#10;Note de frais saisies: {{row.entity.expensesSum | currency}}&#10;Coûts estimés: {{row.entity.totalCost | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var costCellTemplate = '<div class="ui-grid-cell-contents" title="{{row.entity.status==0?\'Pour mémoire - Dossier en prospection&#10;\':\'\'}}Coûts estimés: {{row.entity.totalCost | currency}}&#10;Coûts constatés: {{row.entity.wagesSum + row.entity.accountedCost | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var marginCellTemplate = '<div class="ui-grid-cell-contents" title="Marge finale estimée: {{row.entity.estimatedMargin | number:2}}&#10;Marge actuelle constatée: {{row.entity.wagesSum + row.entity.currentGrossMargin | number:2}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var linkCellTemplateWithBackground = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-danger\': row.entity.status==\'4\', \'bg-warning\': row.entity.status==\'3\', \'bg-success\': row.entity.status==\'2\', \'bg-info\': row.entity.status==\'1\'}"> <a href ui-sref="viewProduction({ productionId : row.entity.code})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var actionLinks = '<div class="ui-grid-cell-contents" title="TOOLTIP"><span class="link text-info glyphicon glyphicon-edit" aria-hidden="true" ng-click="grid.appScope.openModal(\'./partials/modalEditProject.html\', \'modalEditProjectCtrl\', {\'productionCode\' : row.entity.code}, \'lg\')"></span></div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterCsvFilename: 'pwpProds.csv',
			exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
			exporterSuppressColumns: [ 'actions' ],
			exporterMenuPdf: false,
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);

				// Setup events so we're notified when grid state changes.
				$scope.gridApi.core.on.columnVisibilityChanged($scope, saveState);
				$scope.gridApi.core.on.filterChanged($scope, saveState);
				$scope.gridApi.core.on.sortChanged($scope, saveState);
				$scope.gridApi.core.on.scrollEnd($scope, saveState);
			},
			enableCellSelection: false,
			enableRowSelection: true,
			enableSelectAll: true,
			multiSelect: true,
			enableGrouping: false,
			enableColumnResizing: true,
			showColumnFooter: true,
			showSelectionCheckbox: true,
			afterSelectionChange: sumSelectedRows,
			enableFiltering: true,
			columnDefs : [{
				field : "code",
				enableSorting: true,
				width : 80,
				displayName : "Code",
				cellTemplate: linkCellTemplateWithBackground,
				cellClass: "grid-align-center"
			}, {
				field : "description",
				enableSorting: false,
				cellTemplate: linkCellTemplateWithBackground,
				displayName : "Description",
				cellTooltip: function(row,col){if (row.entity.observations) return (row.entity.observations); else return ('Pas d\'obervations particulières');}
			}, {
				field : "observations",
				enableSorting: false,
				visible: false,
				displayName : "Observations"
			}, {
				field : "status",
				width: 70,
				displayName: 'Statut',
				cellClass: "grid-align-center",
				visible : false
			}, {
				field : "transparent",
				width: 70,
				displayName: 'transparent',
				cellClass: "grid-align-center",
				visible : false
			}, {				field : "customerCode",
				cellClass: "grid-align-center",
				displayName: 'Client',
				width: 65,
				visible : false
			}, {
				field : "quotesSum",
				headerTooltip: 'Somme des devis acceptés',
				visible : false,
				enableFiltering: false,
				displayName : "Ventes",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "extraFundings",
				headerTooltip: 'Reports d\'exercice antérieur',
				visible : false,
				enableFiltering: false,
				displayName : "Report précédent",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "carriedForwardFundings",
				headerTooltip: 'Reports exercice suivant',
				visible : false,
				enableFiltering: false,
				displayName : "Report suivant",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "subProdFunding",
				headerTooltip: 'Financement issu d\'un dossier commercial global',
				visible : false,
				enableFiltering: false,
				displayName : "Financement sous-dossier",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "PM",
				enableSorting: true,
				width : 65,
				displayName : "PM",
				cellClass: "grid-align-center"
			}, {
				field : "date",
				enableSorting: true,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				width : 110,
				visible: false,
				displayName : "Date création",
				enableFiltering: false,
				cellClass: "grid-align-center"
			}, {
				field : "updateDate",
				enableSorting: true,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				width : 110,
				visible: false,
				displayName : "Date MAJ",
				enableFiltering: false,
				cellClass: "grid-align-center"
			}, {
				field : "estimatedFundings",
				pinnable: true,
				displayName : "Devis interne",
				width : 130,
				visible : false,
				cellFilter: "currency",
				cellClass: "grid-align-right"
			}, {
				field : "pipeFundingSum",
				headerTooltip: 'Pipe commercial',
				enableFiltering: false,
				displayName : "Pipe",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "pendingQuotesSum",
				headerTooltip: 'Somme des devis en attente',
				enableFiltering: false,
				displayName : "Devis en attente",
				width : 115,
				visible: false,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "fundingsSum",
				headerTooltip: "CA (report / ventes / dossier commercial) [les dossiers annulés ne sont pas comptés]",
				footerTooltip: "CA (report / ventes / dossier commercial) [les dossiers annulés ne sont pas comptés]",
				pinnable: true,
				displayName : 'CA',
				enableFiltering: false,
				width : 115,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: sumProjectsFundings,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "expensesSum",
				headerTooltip: "Notes frais saisies à date",
				pinnable: true,
				displayName : 'Frais saisis',
				enableFiltering: false,
				width : 115,
				visible: false,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: accountedCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "invoicesSum",
				headerTooltip: "Factures saisies à dates",
				pinnable: true,
				displayName : 'Factures saisies',
				enableFiltering: false,
				width : 115,
				visible: false,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: accountedCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "accountedCost",
				pinnable: true,
				headerTooltip: 'Factures et notes de frais constatées',
				displayName : "Comptabilisé",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				cellTemplate: accountedCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				cellTooltip: 'Frais et Factures'
			}, {
				field: "totalWages",
				pinnable: true,
				displayName : "Salaires constatés",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				cellTooltip: 'Salaires',
				visible : false
			}, {
				field : "totalCost",
				pinnable: true,
				displayName : "Coût constatés",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				enableFiltering: false,
				//aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationType: sumProjectsAccountedCosts,
				aggregationHideLabel: true,
				cellTemplate: costCellTemplate,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				headerTooltip: 'Total des coûts constatés(Frais, Factures, Piges) [les dossiers annulés ne sont pas comptés]',
				footerTooltip: 'Total des coûts constatés(Frais, Factures, Piges) [les dossiers annulés ne sont pas comptés]'
				}, {
				field : "finalTotalCost",
				pinnable: true,
				displayName : "Coûts estimés",
				width : 105,
				visible: true,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				enableFiltering: false,
				aggregationType: sumProjectsFinalCosts,
				//aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				cellTemplate: costCellTemplate,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				headerTooltip: 'Coût total final [estimation]',
				footerTooltip: 'Coût total final [estimation]'
				}, {
				field : "currentMargin",
				visible : false,
				displayName : "Marge Act.",
				cellFilter: "number:0",
				enableFiltering: false,
				width : 105,
				type: 'number',
				cellClass: "grid-align-center"
			}, {
				field : "estimatedMargin",
				pinnable: true,
				displayName : "Marge finale",
				enableFiltering: false,
				headerTooltip:'Pourcentage de marge finale estimée si dossier en cours ou constatée si dossier terminé',
				type: 'number',
				cellFilter: "number:1",
				footerCellFilter: "number:2",
				aggregationType: getGlobalMargin,
				aggregationHideLabel: true,
				width : 70,
				cellTemplate: marginCellTemplate,
				footerCellClass: "grid-align-center",
				cellClass: "grid-align-center"
			}, {
				field : "currentGrossMargin",
				pinnable: true,
				displayName : "Marge actuelle",
				enableFiltering: false,
				headerTooltip:'Pourcentage de marge actuel',
				type: 'number',
				cellFilter: "number:1",
				cellTemplate: marginCellTemplate,
				visible: false,
				width : 70,
				cellClass: "grid-align-center"
			}, {
				field : "marginAmount",
				pinnable: true,
				displayName : "Marge brute",
				headerTooltip:'Montant de marge finale estimée si dossier en cours ou constatée si dossier terminé',
				enableFiltering: false,
				width : 105,
				type: 'number',
				cellFilter: "currency",
				footerCellFilter: 'currency',
				aggregationType: sumProjectsGrossMargin,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "actions",
				pinnable: false,
				displayName : "",
				width : 50,
				cellTemplate: actionLinks,
				enableFiltering: false,
				cellClass: "grid-align-center",
				enableSorting: false,
				enableColumnMenu: false
			}]
		};

		$scope.$on('ngGridEventFilter', function(newFilterText){
			if($scope.rowsSelection.length){
				$scope.gridSettings.$gridScope.toggleSelectAll(null, false);
				var selectAllHeader = angular.element(".ngSelectionHeader").scope();
				if(selectAllHeader) selectAllHeader.allSelected = false;
			}
		});

		$scope.$on('ngGridEventSorted', function(newFilterText){
			if($scope.rowsSelection.length){
				$scope.gridSettings.$gridScope.toggleSelectAll(null, false);
				var selectAllHeader = angular.element(".ngSelectionHeader").scope();
				if(selectAllHeader) selectAllHeader.allSelected = false;
			}
		});

		$scope.getProductions();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewProducersCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', '$state', '$filter', 'jsonData', 'localStorageService', viewProducersCtrl]);


	function viewProducersCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, $state, $filter, jsonData, localStorageService) {

		jsonData.getUsers().then(function(users){
			$scope.users = users;
			jsonData.getProductions('').then(function(data){
				$scope.productions = data;
				var producersStats=[];
				angular.forEach($scope.users, function(producer, index){
					var producerProductions=$filter('filter')($scope.productions, {'PM': producer.userId});
					var producerStats={
						'producerId'		: producer.userId,
						'productionCount'	: producerProductions.length,
						'producerFullName'	: producer.fullName,
						'canceled'					: 0,
						'closed'					: 0,
						'clotured'					: 0,
						'opened'					: 0,
						'inPipe'					: 0,
						'engagedAmount'				: 0,
						'canceledAmount'			: 0,
						'closedAmount'				: 0,
						'cloturedAmount'			: 0,
						'openedAmount'				: 0,
						'openedCostsAmount'			: 0,
						'openedGrossMarginAmount'	: 0,
						'openedGrossMargin'			: 0,
						'costsAmount'				: 0,
						'cloturedCostsAmount'		: 0,
						'closedCostsAmount'			: 0,
						'grossMarginAmount'			: 0,
						'grossMargin'				: 0,
						'cloturedGrossMarginAmount'	: 0,
						'cloturedGrossMargin'		: 0,
						'closedGrossMarginAmount'	: 0,
						'closedGrossMargin'			: 0,
						'inPipeAmount'				: 0
					};
					angular.forEach(producerProductions, function(production){
						if(!production.transparent){
							switch(production.status){
								case 0:
									producerStats.inPipe++;
									producerStats.inPipeAmount += production.pendingQuotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
									producerStats.costsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									break;

								case 1:
									producerStats.opened++;
									producerStats.inPipeAmount += production.pendingQuotesSum;
									producerStats.openedAmount += production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
									producerStats.costsAmount += production.invoicesCurrentSum + production.wagesCurrentSum + production.expensesCurrentSum;
									producerStats.openedCostsAmount += production.invoicesCurrentSum + production.wagesCurrentSum + production.expensesCurrentSum;
									break;

								case 2:
									producerStats.closed++;
									producerStats.closedAmount += production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
									producerStats.costsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									producerStats.closedCostsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									break;

								case 3:
									producerStats.clotured++;
									producerStats.cloturedAmount += production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
									producerStats.costsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									producerStats.cloturedCostsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									break;

								case 4:
									producerStats.canceled++;
									producerStats.canceledAmount+= production.canceledFundings;
									producerStats.costsAmount += production.invoicesSum + production.wagesSum + production.expensesSum;
									break;
								}
						}
					});
					producerStats.engagedAmount = producerStats.openedAmount + producerStats.closedAmount + producerStats.cloturedAmount;
					producerStats.grossMarginAmount = producerStats.engagedAmount - producerStats.costsAmount;
					producerStats.openedGrossMarginAmount = producerStats.openedAmount - producerStats.openedCostsAmount;
					producerStats.closedGrossMarginAmount = producerStats.closedAmount - producerStats.closedCostsAmount;
					producerStats.cloturedGrossMarginAmount = producerStats.cloturedAmount - producerStats.cloturedCostsAmount;
					if(producerStats.engagedAmount){
						producerStats.grossMargin = (1 - (producerStats.costsAmount / producerStats.engagedAmount)) * 100;
						producerStats.openedGrossMargin = (1 - (producerStats.openedCostsAmount / producerStats.openedAmount)) * 100;
						producerStats.closedGrossMargin = (1 - (producerStats.closedCostsAmount / producerStats.closedAmount)) * 100;
						producerStats.cloturedGrossMargin = (1 - (producerStats.cloturedCostsAmount / producerStats.cloturedAmount)) * 100;
					} else {
						producerStats.grossMargin = -Infinity;
						producerStats.openedGrossMargin = -Infinity;
						producerStats.closedGrossMargin = -Infinity;
						producerStats.cloturedGrossMargin = -Infinity;
					}
					producersStats.push(producerStats);
				});
				$scope.gridSettings.data=producersStats;
			});
		});

		function globalGrossMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				rowsTotalFundings += row.entity.engagedAmount;
				rowsTotalCost += row.entity.costsAmount;
			});
			return ((1 - rowsTotalCost / rowsTotalFundings) * 100);
		}

		function openedGlobalGrossMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				rowsTotalFundings += row.entity.openedAmount;
				rowsTotalCost += row.entity.openedCostsAmount;
			});
			if(rowsTotalFundings)
				return ((1 - rowsTotalCost / rowsTotalFundings) * 100);
			else
				return -Infinity;
		}

		function closedGlobalGrossMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				rowsTotalFundings += row.entity.closedAmount;
				rowsTotalCost += row.entity.closedCostsAmount;
			});
			if(rowsTotalFundings)
				return ((1 - rowsTotalCost / rowsTotalFundings) * 100);
			else
				return -Infinity;
		}

		function cloturedGlobalGrossMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				rowsTotalFundings += row.entity.cloturedAmount;
				rowsTotalCost += row.entity.cloturedCostsAmount;
			});
			if(rowsTotalFundings)
				return ((1 - rowsTotalCost / rowsTotalFundings) * 100);
			else
				return -Infinity;
		}

		var linkCellTemplateWithBackground = '<div class="ui-grid-cell-contents" title="TOOLTIP"> <a href ui-sref="viewProds({customerCode:\'\', userId : row.entity.producerId})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'producteurs.xlsx',
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
			},
			enableCellSelection: false,
			enableRowSelection: false,
			enableSelectAll: false,
			enableGrouping: false,
			enableColumnResizing: true,
			showColumnFooter: true,
			enableFiltering: false,
			columnDefs : [{
				field : "producerId",
				enableSorting: true,
				width : 80,
				displayName : "PM",
				cellTemplate: linkCellTemplateWithBackground,
				cellClass: "grid-align-center"
			}, {
				field : "producerFullName",
				cellTemplate: linkCellTemplateWithBackground,
				enableSorting: true,
				displayName : "Nom complet"
			}, {
				field : "productionCount",
				enableSorting: true,
				width : 90,
				visible: false,
				cellClass: "grid-align-center",
				displayName : "Dossiers",
				headerTooltip: 'Nombre de dossiers',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "inPipe",
				enableSorting: true,
				width : 100,
				cellClass: "grid-align-center",
				displayName : "Pipe",
				headerTooltip: 'Nombre de dossiers en prospection',
				visible: false,
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "inPipeAmount",
				headerTooltip: 'Somme des dossiers en prospection',
				enableFiltering: false,
				displayName : "CA Pipe",
				width : 125,
				cellFilter: "currency",
				type: 'number',
				visible: true,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "engagedAmount",
				headerTooltip: 'Cumul dossiers en cours, terminés et clôts',
				enableFiltering: false,
				displayName : "CA",
				width : 125,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "grossMarginAmount",
				enableSorting: true,
				displayName : "MB",
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "grossMargin",
				enableSorting: true,
				width : 100,
				visible: true,
				cellClass: "grid-align-center",
				displayName : 'MB %',
				headerTooltip: 'Marge brute en pourcentage',
				cellFilter: 'number:2',
				footerCellFilter: 'number:2',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: globalGrossMargin,
				aggregationHideLabel: true
			}, {
				field : "opened",
				enableSorting: true,
				width : 100,
				cellClass: "grid-align-center",
				displayName : "En cours",
				headerTooltip: 'Nombre de dossiers en cours',
				type: 'number',
				visible: false,
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "openedAmount",
				enableFiltering: false,
				displayName : 'CA ouverts',
				headerTooltip: 'Somme des dossiers ouverts',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "openedGrossMarginAmount",
				enableSorting: true,
				displayName : "MB ouverts",
				headerTooltip: 'Somme MB des dossiers ouverts',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "openedGrossMargin",
				enableSorting: true,
				width : 100,
				visible: true,
				cellClass: "grid-align-center",
				displayName : 'MB ouverts',
				headerTooltip: 'Marge brute des dossiers ouverts en pourcentage',
				cellFilter: 'number:2',
				footerCellFilter: 'number:2',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: openedGlobalGrossMargin,
				aggregationHideLabel: true
			}, {
				field : "closed",
				enableSorting: true,
				width : 100,
				cellClass: "grid-align-center",
				displayName : "Terminés",
				visible: false,
				headerTooltip: 'Nombre de dossiers terminés',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "closedAmount",
				enableFiltering: false,
				displayName : "CA terminés",
				headerTooltip: 'CA des dossiers terminés',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "closedGrossMarginAmount",
				enableSorting: true,
				displayName : "MB terminés",
				headerTooltip: 'Somme MB des dossiers terminés',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "closedGrossMargin",
				enableSorting: true,
				width : 100,
				visible: true,
				cellClass: "grid-align-center",
				displayName : 'MB terminés',
				headerTooltip: 'Marge brute des dossiers terminés en pourcentage',
				cellFilter: 'number:2',
				footerCellFilter: 'number:2',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: closedGlobalGrossMargin,
				aggregationHideLabel: true
			}, {
				field : "clotured",
				enableSorting: true,
				width : 100,
				cellClass: "grid-align-center",
				displayName : "Clôts",
				visible: false,
				headerTooltip: 'Nombre de dossiers clôts',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "cloturedAmount",
				enableFiltering: false,
				displayName : "CA Clôts",
				headerTooltip: 'CA Dossiers clôts',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "cloturedGrossMarginAmount",
				enableSorting: true,
				displayName : "MB clôts",
				headerTooltip: 'MB dossiers clôts',
				width : 125,
				visible: true,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "cloturedGrossMargin",
				enableSorting: true,
				width : 100,
				visible: true,
				cellClass: "grid-align-center",
				displayName : 'MB clôts %',
				headerTooltip: 'Marge brute des dossiers clôts en pourcentage',
				cellFilter: 'number:2',
				footerCellFilter: 'number:2',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: cloturedGlobalGrossMargin,
				aggregationHideLabel: true
			}, {
				field : "canceled",
				enableSorting: true,
				width : 100,
				visible: false,
				cellClass: "grid-align-center",
				displayName : "Annulés",
				headerTooltip: 'Nombre de dossiers annulés',
				type: 'number',
				footerCellClass: "grid-align-center",
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true
			}, {
				field : "canceledAmount",
				enableFiltering: false,
				displayName : "CA Annulés",
				headerTooltip: 'CA des dossiers annulés',
				width : 125,
				visible: false,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "costsAmount",
				enableSorting: true,
				displayName : "Coûts Ext.",
				headerTooltip: 'Total flux externes',
				width : 125,
				visible: false,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}]
		};
	}
})();
(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewProductionCtrl',['$scope', '$rootScope', '$location', '$log', '$http', '$timeout', '$stateParams', '$state', '$filter', 'dialogs', 'jsonData', 'Notification', viewProductionCtrl]);

	function viewProductionCtrl($scope, $rootScope, $location, $log, $http, $timeout, $stateParams, $state, $filter, dialogs, jsonData, Notification) {
		$scope.tabIndex = 1;
		$scope.disableNewQuote = false;
		$scope.pendingQuotesCount = 0;
		$scope.pendingQuotesAmount = 0;
		$scope.orderedQuotesAmount = 0;
		$scope.engagedQuotesAmount = 0;
		$scope.currentDate = new Date();
		$scope.multimagesProjects = [];
		//$scope.currentYear = $scope.currentDate.getFullYear();
		$scope.currentYear =  $config.financialYear;


		$scope.isSameInternalQuoteLine = function (actual, expected){
			return (actual==expected);
		};

		$scope.getProduction = function(){
			jsonData.getProduction($stateParams.productionId).then(
				function(data){
					// Add text field for UI
					angular.forEach(data.invoices, function(invoice, key){
						invoice.currentAmountField = $filter('number')(invoice.currentAmount, 2);
						if(typeof invoice.checked === 'number'){
							invoice.checked = invoice.checked.toString();
						}
						//invoice.checked=parseInt(invoice.checked);
					});

					angular.forEach(data.wages, function(wage, key){
						wage.currentAmountField = $filter('number')(wage.currentAmount, 2);
					});

					angular.forEach(data.expenses, function(expense, key){
						expense.currentAmountField = $filter('number')(expense.currentAmount, 2);
					});

					$scope.productionData = data;

					$scope.calculate();

					$scope.getMultimagesProject();

					if(parseFloat($scope.productionData.estimatedFundings)===0.0)
						Notification.warning({message: 'Devis Interne absent', delay: 15000});
					if($scope.productionData.totalFundings===0.0 && $scope.productionData.status>0 && $scope.productionData.prodType != 'C')
						Notification.error({message: 'Dossier sans vente', delay: 15000});
					if($scope.productionData.totalFundings===0.0 && $scope.productionData.status>0 && $scope.productionData.totalCost>0 && $scope.productionData.prodType != 'C')
						Notification.error({message: 'Charges sans financement', delay: 15000});				}
			);
		};

		$scope.calculate = function(){
			$log.debug("start calculation");
			$scope.productionData.jsDate = new Date(Date.parse($scope.productionData.date.replace(/-/g,'/')));
			$scope.productionData.jsUpdateDate = new Date(Date.parse($scope.productionData.updateDate.replace(/-/g,'/')));
			var internalQuoteLine = 0;
			var lineSum = 0.0;
			$scope.pendingQuotesCount = 0;
			$scope.pendingQuotesAmount = $scope.engagedQuotesAmount = $scope.orderedQuotesAmount = $scope.billedQuotesAmount = 0.0;
			$scope.productionData.invoicesCount = $scope.productionData.wagesCount = $scope.productionData.expensesCount = 0;
			$scope.productionData.invoicesCurrentSum = $scope.productionData.expensesCurrentSum = $scope.productionData.wagesCurrentSum = $scope.productionData.currentTotalCost = 0.0;
			$scope.productionData.invoicesSum = parseFloat($scope.productionData.invoicesSum);
			$scope.productionData.expensesSum = parseFloat($scope.productionData.expensesSum);
			$scope.productionData.totalCost = parseFloat($scope.productionData.invoicesSum) + parseFloat($scope.productionData.wagesSum) + parseFloat($scope.productionData.expensesSum);
			$scope.productionData.estimatedTotalCost = parseFloat($scope.productionData.invoicesEstimatedSum) + parseFloat($scope.productionData.wagesEstimatedSum) + parseFloat($scope.productionData.expensesEstimatedSum);

			// Invoices loop
			internalQuoteLine = 0;
			angular.forEach($scope.productionData.invoices, function(invoice, key){
				if(invoice.amount===null){
					$scope.productionData.invoicesCount++;
					lineSum = 0.0;
					angular.forEach($scope.productionData.invoices, function(invoiceLoop, keyLoop){
						if(invoiceLoop.internalQuoteLine==internalQuoteLine)
							lineSum += invoiceLoop.amount;
					});
					internalQuoteLine++;
					invoice.lineSum = lineSum;
				}
				if(parseFloat(invoice.currentAmount)){
					$scope.productionData.currentTotalCost += parseFloat(invoice.currentAmount);
					$scope.productionData.invoicesCurrentSum += parseFloat(invoice.currentAmount);
				}
			});

			// Wages loop
			internalQuoteLine = 0;
			angular.forEach($scope.productionData.wages, function(wage, key){
				if(wage.amount===null){
					$scope.productionData.wagesCount++;
					lineSum = 0.0;
					angular.forEach($scope.productionData.wages, function(wageLoop, keyLoop){
						if(wageLoop.internalQuoteLine==internalQuoteLine){
							lineSum += parseFloat(wageLoop.amount);
						}
					});
					wage.lineSum = lineSum;
					internalQuoteLine++;
				}
				if(parseFloat(wage.currentAmount)){
					$scope.productionData.currentTotalCost += parseFloat(wage.currentAmount);
					$scope.productionData.wagesCurrentSum += parseFloat(wage.currentAmount);
				}
			});

			// Expenses loop
			internalQuoteLine = 0;
			angular.forEach($scope.productionData.expenses, function(expense, key){
				if(expense.amount===null){
					$scope.productionData.expensesCount++;
					lineSum = 0.0;
					angular.forEach($scope.productionData.expenses, function(expenseLoop, keyLoop){
						if(expenseLoop.internalQuoteLine==internalQuoteLine)
							lineSum += parseFloat(expenseLoop.amount);
					});
					internalQuoteLine++;
					expense.lineSum = lineSum;
				}
				if(parseFloat(expense.currentAmount)){
					$scope.productionData.currentTotalCost += parseFloat(expense.currentAmount);
					$scope.productionData.expensesCurrentSum += parseFloat(expense.currentAmount);
				}
			});

			// Quotes loop
			$log.debug('loop on production quotes');
			angular.forEach($scope.productionData.quotes, function(quote, key){
				switch(quote.status){
					case 0:
						$scope.pendingQuotesCount += 1;
						$scope.pendingQuotesAmount += parseFloat(quote.amount);
						break;

					case 1:
						$scope.engagedQuotesAmount += quote.amount;
						break;

					case 2: //ordered
						$scope.engagedQuotesAmount += quote.amount;
						$scope.orderedQuotesAmount += quote.amount;
						break;

					case 3:
						$scope.engagedQuotesAmount += quote.amount;
						$scope.orderedQuotesAmount += quote.amount;
						$scope.billedQuotesAmount += quote.amount;
						break;
				}
			});

			// Get Fundings values according production status
			switch($scope.productionData.status){
				case 0:
					$scope.productionData.totalFundings = 0;
					$scope.productionData.currentTotalFundings = $scope.productionData.estimatedTotalFundings;
					$scope.disableNewCost = true;
					break;

				case 1:
				case 2:
					$scope.productionData.totalFundings = $scope.productionData.quotesSum + $scope.productionData.extraFundings + $scope.productionData.subProdFunding - $scope.productionData.carriedForwardFundings + $scope.productionData.partialClosure;
					$scope.productionData.currentTotalFundings = $scope.productionData.totalFundings;
					$scope.disableNewCost = false;
					break;

				case 3:
					$scope.productionData.totalFundings = $scope.productionData.quotesSum + $scope.productionData.extraFundings + $scope.productionData.subProdFunding - $scope.productionData.carriedForwardFundings + $scope.productionData.partialClosure;
					$scope.productionData.currentTotalFundings = $scope.productionData.totalFundings;
					$scope.disableNewCost = true;
					break;

				case 4:
					$scope.productionData.totalFundings = 0;
					$scope.productionData.currentTotalFunding = 0;
					$scope.disableNewCost = true;
					break;
			}

			// Gross margin percentage
			$scope.productionData.grossMargin = (1 - ($scope.productionData.totalCost/ (parseFloat($scope.productionData.totalFundings)))) * 100;
			$scope.productionData.estimatedGrossMargin = (1 - ($scope.productionData.estimatedTotalCost/ (parseFloat($scope.productionData.estimatedFundings)))) * 100;
			$scope.productionData.currentGrossMargin = (1 - ($scope.productionData.currentTotalCost / $scope.productionData.currentTotalFundings)) * 100;

			// Margin amount
			$scope.productionData.marginAmount = $scope.productionData.totalFundings - $scope.productionData.totalCost;
			$scope.productionData.estimatedMarginAmount = parseFloat($scope.productionData.estimatedFundings) + parseFloat($scope.productionData.extraFundings)  - parseFloat($scope.productionData.carriedForwardFundings) - $scope.productionData.estimatedTotalCost;
			$scope.productionData.currentMarginAmount = $scope.productionData.currentTotalFundings - $scope.productionData.currentTotalCost;

			// UI flags
			$scope.productionData.invoicesToCheck = $scope.productionData.invoicesCheckedCountMax - $scope.productionData.invoicesCheckedCount;
			$scope.disableNewQuote = parseFloat($scope.productionData.estimatedFundings)===0.0;

			$log.debug("calculate OK");

			$log.debug($scope.productionData);
		};

		$scope.updateProjectData = function(data){

			$log.info('Project updated');
			if(angular.isDefined(data.invoiceId)){
				$log.debug ('invoice marked for save');
				data.currentAmount = parseFloat(data.currentAmountField.replace(/\s/g, ''));
				data.updated = true;
			}

			if(angular.isDefined(data.wageId)){
				data.updated = true;
				$log.debug ('wage marked for save');
				data.currentAmount = parseFloat(data.currentAmountField.replace(/\s/g, ''));
			}

			if(angular.isDefined(data.expenseId)){
				data.updated = true;
				$log.debug ('expense marked for save');
				data.currentAmount = parseFloat(data.currentAmountField.replace(/\s/g, ''));
			}

			$scope.calculate();
		};

		$scope.saveData = function(data){
			var saveFlag=false;
			if(typeof data.currentAmountField === 'string'){
				$log.debug(data.currentAmountField.replace(/\s/g, '').replace(',', '.'));
				data.currentAmount = parseFloat(data.currentAmountField.replace(',', '.').replace(/\s/g, ''));
			}
			if(data.currentAmount < data.lineSum){
				data.currentAmount = data.lineSum;
			}

			$scope.calculate();
			if(angular.isDefined(data.invoiceId) && angular.isDefined(data.updated))
				data.currentAmountField = $filter('number')(data.currentAmount, 2);
				if(data.updated){
					data.updated = false;
					saveFlag=true;
				}

			if(angular.isDefined(data.wageId) && angular.isDefined(data.updated))
				data.currentAmountField = $filter('number')(data.currentAmount, 2);
				if(data.updated){
					data.updated = false;
					saveFlag=true;
				}

			if(angular.isDefined(data.expenseId) && angular.isDefined(data.updated))
				data.currentAmountField = $filter('number')(data.currentAmount, 2);
				if(data.updated){
					data.updated = false;
					saveFlag=true;
				}

			if(saveFlag){
				$http.post('/json/setProductionAccountEstimatedValue.php', {
					'data': data
				}).then(
					function(response) {
						Notification.info('Données sauvegardées');
					//$scope.getProduction();
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			}
		};

		$scope.editInvoice = function(index, invoice){
			var modalInstance = $scope.openModal('./partials/modalEditInvoice.html', 'modalEditInvoiceCtrl', {'productionCode' : $scope.productionData.code, 'customerCode' : $scope.productionData.customer.code, 'productionDescription' : $scope.productionData.description, 'customerName' : $scope.productionData.customer.name, 'invoice' : invoice, 'orderedAmount': $scope.orderedQuotesAmount}, '');

			modalInstance.result.then(function (invoiceFromModal) {
				$http.post('/json/setInvoice.php', {
					'invoiceId'			: invoiceFromModal.id,
					'productionId'		: $scope.productionData.prodId,
					'productionCode'	: $scope.productionData.code,
					'internalQuoteLine'	: index,
					'amount'			: invoiceFromModal.amount,
					'date'				: $filter('date')(invoiceFromModal.date, 'yyyy-MM-dd'),
					'supplier'			: angular.isObject(invoiceFromModal.supplier)? invoiceFromModal.supplier.description : invoiceFromModal.supplier,
					'number'			: invoiceFromModal.number,
					'description'		: invoiceFromModal.description
				}).then(
					function(response) {
						$log.debug(response.data);
						$scope.getProduction();
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			});
		};

		$scope.editWage = function(index, wage, qualification){
			var modalInstance = $scope.openModal('./partials/modalEditWage.html', 'modalEditWageCtrl', {'productionCode' : $scope.productionData.code, 'customerCode' : $scope.productionData.customer.code, 'productionDescription' : $scope.productionData.description, 'customerName' : $scope.productionData.customer.name, 'wage' : wage, 'qualification' : qualification, 'orderedAmount': $scope.orderedQuotesAmount}, 'lg');

			modalInstance.result.then(
				function (wageFromModal) {
					$log.debug(wageFromModal);
					$http.post('/json/setWage.php', {
						'wage'				: wageFromModal,
						'productionCode'	: $scope.productionData.code,
						'productionId'		: $scope.productionData.prodId,
						'internalQuoteLine'	: index
					}).then(
						function(response) {
							//$log.info(response.data);
							$scope.getProduction();
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.editExpense = function(index, expense){
			var modalInstance = $scope.openModal('./partials/modalEditExpense.html', 'modalEditExpenseCtrl', {'productionCode' : $scope.productionData.code, 'productionId' : $scope.productionData.prodId, 'customerCode' : $scope.productionData.customer.code, 'productionDescription' : $scope.productionData.description, 'customerName' : $scope.productionData.customer.name, 'expense' : expense, 'orderedAmount': $scope.orderedQuotesAmount}, '');

			modalInstance.result.then(
				function (expenseFromModal) {
					expenseFromModal.date = angular.isDate(expenseFromModal.date)? expenseFromModal.date.toISOString() :  new Date(expenseFromModal.date).toISOString();
					$http.post('/json/setExpense.php', {
						'expense'			: expenseFromModal,
						'productionCode'	: $scope.productionData.code,
						'productionId'		: $scope.productionData.prodId,
						'internalQuoteLine'	: index
					}).then(
						function(response) {
							$scope.getProduction();
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.deleteExpense = function(expense){
			var dlg = dialogs.confirm('Frais', 'Suppression des frais de ' + expense.employeeFullName +' ['+expense.amount+'€] ?', {'size' : 'sm'});
			dlg.result.then(
				function(btn){
					$http.post('/json/deleteExpense.php', {
						'expenseId'			: expense.expenseId,
						'productionCode'	: $scope.productionData.code
					}).then(
						function(response) {
							$scope.getProduction();
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function() {
					// no deletion
				});
		};

		$scope.addQuote = function(){
			var modalInstance = $scope.openModal('./partials/modalEditQuote.html', 'modalEditQuoteCtrl', {'productionCode' : $scope.productionData.code, 'productionId' : $scope.productionData.prodId, 'customerCode' : $scope.productionData.customer.code, 'productionDescription' : $scope.productionData.description, 'customerName' : $scope.productionData.customer.name, 'orderedAmount': $scope.orderedQuotesAmount}, '');

			modalInstance.result.then(
				function (quoteFromModal) {
					$http.post('/json/setQuote.php', {
						'quoteId'			: quoteFromModal.quoteId,
						'index'				: quoteFromModal.index,
						'quoteNumber'		: quoteFromModal.quoteNumber,
						'productionCode'	: $scope.productionData.code,
						'customerRecipient'	: quoteFromModal.customerRecipient,
						'deliveryDate' 		: quoteFromModal.deliveryDate===null ? null : $filter('date')(quoteFromModal.deliveryDate, 'yyyy-MM-dd'),
						'sourceQuoteId'		: quoteFromModal.sourceQuoteId,
						'cancelSourceQuote'	: quoteFromModal.cancelSourceQuote,
						'description'		: quoteFromModal.description,
						'observations'		: quoteFromModal.observations,
						'customerCode'		: $scope.productionData.customer.code,
						'customerName'		: $scope.productionData.customer.name,
						'status'			: quoteFromModal.status
					}).then(
						function(response) {
							if(quoteFromModal.sourceQuoteId!='0'){
								$http.post('parseSpreadsheet.php', {'fileName' : response.data.newQuoteFileName}).then(
									function(response) {
										$scope.getProduction();
										$scope.getProductionsQuotes();
									},
									function(httpError){
										throw httpError.status + " : " + httpError.data;
									}
								);
							} else {
								$scope.getProduction();
								$scope.getProductionsQuotes();
							}
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.editQuotes =function(){
			var modalInstance = $scope.openModal('./partials/modalEditQuotes.html', 'modalEditQuotesCtrl', {'productionCode' : $scope.productionData.code, 'customerCode' : $scope.productionData.customer.code, 'productionDescription' : $scope.productionData.description, 'customerName' : $scope.productionData.customer.name, 'quotes' : $scope.productionData.quotes, 'project': $scope.productionData}, 'lg');

			modalInstance.result.then(
				function (quotesFromModal) {
					$log.debug(quotesFromModal);
					$scope.getProduction();
					$scope.getProductionsQuotes();
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.editQuote = function(quote){
			var modalInstance = $scope.openModal('./partials/modalEditQuote.html', 'modalEditQuoteCtrl', {'productionCode' : $scope.productionData.code, 'customerCode' : $scope.productionData.customer.code, 'quoteNumber' : quote.quoteNumber, 'orderedAmount': $scope.orderedQuotesAmount}, '');

			modalInstance.result.then(
				function (quoteFromModal) {
					$http.post('/json/setQuote.php', {
						'quoteId'			: quoteFromModal.quoteId,
						'index'				: quoteFromModal.index,
						'quoteNumber'		: quoteFromModal.quoteNumber,
						'productionCode'	: $scope.productionData.code,
						'customerRecipient'	: quoteFromModal.customerRecipient,
						'description'		: quoteFromModal.description,
						'deliveryDate' 		: quoteFromModal.deliveryDate===null ? null : $filter('date')(quoteFromModal.deliveryDate, 'yyyy-MM-dd'),
						'observations'		: quoteFromModal.observations,
						'customerCode'		: $scope.productionData.customer.code,
						'customerName'		: $scope.productionData.customer.name,
						'status'			: quoteFromModal.status
					}).then(
						function(response) {
							$scope.getProduction();
							$scope.getProductionsQuotes();
						},
						function(httpError){
							throw httpError.status + " : " + httpError.data;
						}
					);
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.getQuoteInfo = function(quote){
			var quoteInfoText;
			$log.debug(sprintf('getQuoteInfo %s', quote.status));

			switch(parseInt(quote.status)){
				case 0:
					quoteInfoText = 'En cours de rédaction ou en attente de validation';
					break;
				case 1:
					quoteInfoText = 'Validé';
					break;
				case 2:
					quoteInfoText = 'Commandé';
					break;
				case 3:
					quoteInfoText = 'Facturé';
					break;
				case 4:
					quoteInfoText = 'Annulé';
					break;
			}
			return(quoteInfoText);
		};

		$scope.deleteInvoice = function(invoice){
			var dlg = dialogs.confirm('Facture', 'Suppression de la facture de ' + invoice.supplier +' ['+invoice.amount+'€] ?', {'size' : 'sm'});
			dlg.result.then(function(btn){
				$http.post('/json/deleteInvoice.php', {
					'invoiceId'			: invoice.invoiceId,
					'productionCode'	: $scope.productionData.code
				}).then(
					function(response) {
						$scope.getProduction();
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			});
		};

		$scope.updateInvoiceLineStatus = function(invoice){
			if(invoice.checked){
				invoice.currentAmount = invoice.lineSum;
				$scope.updateProjectData(invoice);
				$scope.saveData(invoice);
			}

			$http.post('/json/updateInvoiceStatus.php', {
				'invoice': invoice
			}).then(
				function(response){
					$scope.getProduction();
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

		$scope.getProductionsQuotes = function(){
			jsonData.getQuotes({'productionCode':$stateParams.productionId}).then(
				function(data){
					$scope.quotes = data;
					angular.forEach($scope.quotes, function(quote, key){
						$scope.disableNewQuote = parseInt(quote.amount) === 0;
					}
				);
			});
		};

		$scope.viewMultimagesProjects =function(){
			var modalInstance = $scope.openModal('./partials/modalViewMultimagesProjects.html', 'modalViewMultimagesProjects', {
				'projects' : $scope.multimagesProjects
			}, 'lg');

			modalInstance.result.then(
				function () {
				},
				function () {
					$log.debug('Modal dismissed at: ' + new Date());
				}
			);
		};

		$scope.getMultimagesProject = function () {
			$http.get('https://multimages.net/api/search/multimagesProjects/productionCode/' + $scope.productionData.jsDate.getFullYear() + '/' + $stateParams.productionId, {
			}).then(
				function(response) {
					$log.debug('Multimages search on ' +  $stateParams.productionId);
					$log.debug(response.data);
					$scope.multimagesProjects = response.data;
				},
				function(httpError){
					throw httpError.status + " : " + httpError.data;
				}
			);
		};

	    $scope.$watch(function(scope){ return $scope.tabIndex;}, function(newValue, oldValue ){
	    	$location.hash($scope.tabIndex);
	    });

		$scope.$on("$locationChangeSuccess", function () {
			if($location.hash()){
				$scope.tabIndex = parseInt($location.hash());
			}
		});

		if($location.hash()){
			$scope.tabIndex = parseInt($location.hash());
		} else
			$location.hash(1);

		$scope.getProduction();
		$scope.getProductionsQuotes();

		$scope.$on("$destroy", function() {
			Notification.clearAll();
    	});
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewProductionsCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', '$filter','$stateParams', '$window', 'localStorageService', 'jsonData', ViewProductionsCtrl]);

	function ViewProductionsCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, $filer, $stateParams, $window, localStorageService, jsonData) {
		//$log.debug(sprintf('Window innerHeight %s', $window.innerHeight));

		$scope.customerCode = $stateParams.customerCode;
		$scope.statusFilter = localStorageService.get('productionsStatusFilters') ? localStorageService.get('productionsStatusFilters') : {opened: true, current: true, closed: true, clotured: true, canceled:false};
		if(!$stateParams.userId){
			$scope.gridFilters = localStorageService.get('productionsFilters') ? localStorageService.get('productionsFilters') : {prodType: '', PM: '', customerCode: ''};
		}
		else
			$scope.gridFilters={prodType: '', PM: $stateParams.userId, customerCode: ''};
		$scope.productionsCount = {'opened':0, 'current':0, 'finished':0, 'clotured':0, 'canceled':0};
		$scope.rowsSelection = [];

		$scope.getProductions = function(){
			jsonData.getProductions($stateParams.customerCode).then(function(data){
				var state = localStorageService.get('productionsGridState');
				$scope.productions = data;
				$scope.filterDatas();
				if(state)
					$timeout(function(){restoreState(state);}, 500);
				$log.debug('getProductions');
				$log.debug($scope.productionsArray);
			});
		};

		jsonData.getUsers().then(function(users){
			$scope.users = users;
		});

		jsonData.getProductionTypes().then(function(productionTypes){
			$scope.productionTypes = productionTypes;
		});

		jsonData.getActiveCustomers().then(function(customers){
			$scope.customers = customers;
		});

		$scope.setProductionsStatusFilter = function(productionStatus){
			switch(productionStatus){
				case 'current':
				case 'closed':
				case 'clotured':
				case 'opened':
					/*if($scope.statusFilter.opened || $scope.statusFilter.current || $scope.statusFilter.closed || $scope.statusFilter.clotured){
						$scope.setColumnVisibility('marginAmount', true);
						$scope.statusFilter.canceled=false;
					}*/
					break;
				case 'canceled':
					/*if($scope.statusFilter.canceled){
						$scope.setColumnVisibility('marginAmount', true);
						$scope.statusFilter.opened=false;
						$scope.statusFilter.current=false;
						$scope.statusFilter.closed=false;
						$scope.statusFilter.clotured=false;
					}*/
					break;
			}
			$scope.filterDatas();
		};

		$scope.filterDatas = function (){
			localStorageService.set('productionsStatusFilters', $scope.statusFilter);
			localStorageService.set('productionsFilters', $scope.gridFilters);
			var productionsArray = [];
			$scope.productionsCount = {'opened':0, 'current':0, 'finished':0, 'clotured':0, 'canceled':0};

			angular.forEach($scope.productions, function(production, key){
				if($scope.statusFilter.opened && production.status===0 || $scope.statusFilter.current && production.status===1 || $scope.statusFilter.closed && production.status===2 || $scope.statusFilter.clotured && production.status===3 || $scope.statusFilter.canceled && production.status===4) {
					if(FilterProduction(production)){
						var accountedCost = production.invoicesSum + production.expensesSum;
						var totalCost = production.invoicesSum + production.wagesSum + production.expensesSum;
						var currentTotalCost = production.invoicesCurrentSum + production.wagesCurrentSum + production.expensesCurrentSum;
						var date = new Date(production.date.substring(0, 10));
						var updateDate = new Date(production.updateDate.substring(0, 10));
						var finalTotalCost = 0;
						var finalGrossMargin = -Infinity;
						var currentGrossMargin = -Infinity;
						var grossMarginAmount = 0;
						var pipeFundingSum = 0;
						var fundingsSum = 0;

						switch(production.status){
							case 0: // Prod open
								pipeFundingSum = production.pendingQuotesSum + production.extraFundings + production.partialClosure - production.carriedForwardFundings;
								finalTotalCost = totalCost;
								$scope.productionsCount.opened++;
								break;

							case 1: // Prod in production
								finalTotalCost = parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
								pipeFundingSum = production.pendingQuotesSum;
								fundingsSum = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
								$scope.productionsCount.current++;
								break;

							case 2: // Prod is finished
								finalTotalCost = parseFloat(production.invoicesCurrentSum) + parseFloat(production.wagesCurrentSum) + parseFloat(production.expensesCurrentSum);
								fundingsSum = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
								$scope.productionsCount.finished++;
								break;

							case 3: // prod clotured
								finalTotalCost = totalCost;
								fundingsSum = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings;
								$scope.productionsCount.clotured++;
								break;

							case 4: // prod cancelled
								finalTotalCost = totalCost;
								pipeFundingSum = production.canceledFundings;
								$scope.productionsCount.canceled++;
								break;
						}

						if(production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings > 0){
						//if (true) {
							switch (parseInt(production.status)){
								case 0: // Prod open
									finalGrossMargin = Infinity;
									currentGrossMargin = -Infinity;
									grossMarginAmount = -totalCost;
									break;

								case 1: // Prod in production
								case 2: // Prod is finished
									finalGrossMargin = (1 - (finalTotalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings))) * 100;

									currentGrossMargin = (1 - (totalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure- production.carriedForwardFundings))) * 100;

									grossMarginAmount = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings - finalTotalCost;
									break;

								case 3: // Prod is clotured
									finalGrossMargin = (1 - (totalCost / (production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings))) * 100;
									currentGrossMargin = finalGrossMargin;
									grossMarginAmount = production.quotesSum + production.extraFundings + production.subProdFunding + production.partialClosure - production.carriedForwardFundings - totalCost;
									break;

								case 4: // prod cancelled
									grossMarginAmount = -totalCost;
									break;
							}
						} else {
							finalGrossMargin = Infinity;
							currentGrossMargin = -Infinity;
							grossMarginAmount = -totalCost;
						}

						date = new Date(production.date.substring(0, 10));
						productionsArray.push({
							'code' : production.code,
							'customerCode' : production.customerCode,
							'prodType': production.prodType,
							'observations' : production.observations,
							'productionTypeDescription' : production.productionDescription,
							'extraFundings' : production.extraFundings,
							'subProdFunding' : production.subProdFunding,
							'carriedForwardFundings': production.carriedForwardFundings,
							'partialClosure': production.partialClosure,
							'transparent': production.transparent,
							'PM' : production.PM,
							'date' : date,
							'updateDate' : updateDate,
							'status' : production.status,
							'description' : production.description,
							'fundingsSum' : fundingsSum,
							'quotesSum'	: production.quotesSum,
							'pendingQuotesSum' : production.pendingQuotesSum,
							'pipeFundingSum': pipeFundingSum,
							'accountedCost': accountedCost,
							'expensesSum':  production.expensesSum,
							'invoicesSum': production.invoicesSum,
							'finalTotalCost': finalTotalCost,
							'totalCost' : totalCost,
							'estimatedFundings' : production.estimatedFundings,
							'totalWages' : production.wagesSum,
							'marginAmount' : grossMarginAmount,
							'estimatedMargin' : finalGrossMargin,
							'currentGrossMargin': currentGrossMargin,
							'currentMargin' : currentGrossMargin,
							'fileName' : production.fileName,
							'invoicesToCheck' : production.invoicesCheckedCountMax-production.invoicesCheckedCount
						});
					}
				}
			});
			$log.debug('Productions Count');
			$log.debug($scope.productionsCount);

			$scope.gridSettings.data = productionsArray;
			$log.debug(sprintf('Productions filtered : %s', $scope.gridSettings.data.length));
		};

		$scope.downloadFile = function(fileName, customerCode){
			$http.post('/json/downloadFile.php', {
				'fileName': fileName,
				'customerCode': customerCode
			});
		};

		$scope.setGridFilter = function(filter, value){
			$scope.gridFilters[filter]=value;
			$scope.filterDatas();
		};

		$scope.resetGridFilters = function(){
			angular.forEach($scope.gridFilters, function(gridFilter, key){
				$scope.gridFilters[key]='';
			});
			$scope.filterDatas();
		};

		$scope.setColumnVisibility = function (fieldName, visibility){
			angular.forEach($scope.gridSettings.columnDefs, function(columnDef, key){
				if(columnDef.field==fieldName){
					columnDef.visible = visibility;
				}
			});
		};

		function FilterProduction(production){
			var isSelected = true;
			angular.forEach($scope.gridFilters, function(gridFilter, key){
				if(gridFilter && production[key]!=gridFilter)
					isSelected = false;
			});
			return isSelected;
		}

		function sumSelectedRows(){
			saveState();
			$scope.rowsTotalFundings=0;
			$scope.rowsTotalCost=0;
			$scope.rowsSelection = $scope.gridApi.selection.getSelectedRows();
			angular.forEach($scope.rowsSelection, function(row, index){
				if (row.status<4  && !row.transparent) {
					$scope.rowsTotalFundings += row.fundingsSum;
					$scope.rowsTotalCost += row.finalTotalCost;
				}
			});
			if($scope.rowsTotalFundings!==0)
				$scope.rowsMargin = (1 - ($scope.rowsTotalCost / $scope.rowsTotalFundings )) *100;
			else
				$scope.rowsMargin = 100;
			$scope.rowsMarginAmount = $scope.rowsTotalFundings - $scope.rowsTotalCost;
		}

		function sumProjectsFundings(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if (row.entity.status<4 && !row.entity.transparent)
					sum += row.entity.fundingsSum;
			});
			return sum;
		}

		function sumProjectsPipe(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.status<4 || $scope.statusFilter==='closed')
					sum += row.entity.pendingQuotesSum;
			});
			return sum;
		}

		function sumProjectsCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				sum += row.entity.finalTotalCost;
			});
			return sum;
		}

		function sumProjectsGrossMargin(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if (!row.entity.transparent) {
					if (row.entity.status<4) {
						sum += row.entity.fundingsSum;
					}
					sum -=row.entity.finalTotalCost;
				}
			});
			return sum;
		}

		function sumProjectsFinalCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.finalTotalCost;
				}
			});
			return sum;
		}

		function sumProjectsTotalCosts(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.totalCost;
				}
			});
			return sum;
		}

		function sumProjectsTotalWages(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.totalWages;
				}
			});
			return sum;
		}

		function sumProjectsInvoices(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.invoicesSum;
				}
			});
			return sum;
		}

		function sumProjectsExpenses(rows){
			var sum = 0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					sum += row.entity.expensesSum;
				}
			});
			return sum;
		}

		function getGlobalMargin(rows){
			var rowsTotalFundings=0;
			var rowsTotalCost=0;
			angular.forEach(rows, function(row, index){
				if(row.entity.transparent === 0){
					switch(row.entity.status){
						case 2:
						case 3:
							rowsTotalCost += row.entity.totalCost;
							break;
						case 0:
						case 1:
						case 4:
							rowsTotalCost += row.entity.finalTotalCost;
							break;
					}
				}
			});
			//return (1 - rowsTotalCost / (rowsTotalFundings)) * 100;
			return ((1 - rowsTotalCost / (sumProjectsFundings(rows))) * 100);
		}

		function saveState() {
			var state = $scope.gridApi.saveState.save();
				localStorageService.set('productionsGridState', state);
				$log.debug('Save productions grid state');
		}

		function restoreState(state) {
			if (state){
				$log.debug('Restore productions grid state');
				$scope.gridApi.saveState.restore($scope, state);
			}
		}

		function rowEntitySave(row) {
			return row.code;
		}

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a href ui-sref="viewProduction({ productionId : row.entity.code})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var fundingCellTemplate = '<div class="ui-grid-cell-contents" title="Vente: {{row.entity.quotesSum | currency}}&#10;Report: {{row.entity.extraFundings | currency}}&#10;Projet principal: {{row.entity.subProdFunding | currency}}&#10;Devis en attente: {{row.entity.pendingQuotesSum | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var accountedCellTemplate = '<div class="ui-grid-cell-contents" title="Factures saisies: {{row.entity.invoicesSum | currency}}&#10;Note de frais saisies: {{row.entity.expensesSum | currency}}&#10;Coûts estimés: {{row.entity.totalCost | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var costCellTemplate = '<div class="ui-grid-cell-contents" title="{{row.entity.status==0?\'Pour mémoire - Dossier en prospection&#10;\':\'\'}}Coûts estimés: {{row.entity.totalCost | currency}}&#10;Coûts constatés: {{row.entity.wagesSum + row.entity.accountedCost | currency}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var marginCellTemplate = '<div class="ui-grid-cell-contents" title="Marge finale estimée: {{row.entity.estimatedMargin | number:2}}&#10;Marge actuelle constatée: {{row.entity.wagesSum + row.entity.currentGrossMargin | number:2}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var linkCellTemplateWithBackground = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-danger\': row.entity.status==\'4\', \'bg-warning\': row.entity.status==\'3\', \'bg-success\': row.entity.status==\'2\', \'bg-info\': row.entity.status==\'1\'}"> <a href ui-sref="viewProduction({ productionId : row.entity.code})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a> </div>';

		var actionLinks = '<div class="ui-grid-cell-contents" title="TOOLTIP"><span class="link text-info glyphicon glyphicon-edit" aria-hidden="true" ng-click="grid.appScope.openModal(\'./partials/modalEditProject.html\', \'modalEditProjectCtrl\', {\'productionCode\' : row.entity.code}, \'lg\')"></span></div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'dossiers.xlsx',
			exporterSuppressColumns: [ 'actions' ],
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);

				// Setup events so we're notified when grid state changes.
				$scope.gridApi.core.on.columnVisibilityChanged($scope, saveState);
				$scope.gridApi.core.on.filterChanged($scope, saveState);
				$scope.gridApi.core.on.sortChanged($scope, saveState);
				$scope.gridApi.core.on.scrollEnd($scope, saveState);
			},
			enableCellSelection: false,
			enableRowSelection: true,
			enableSelectAll: true,
			multiSelect: true,
			enableGrouping: false,
			enableColumnResizing: true,
			showColumnFooter: true,
			showSelectionCheckbox: true,
			afterSelectionChange: sumSelectedRows,
			enableFiltering: true,
			saveSelection: true,
			saveRowIdentity: rowEntitySave,
			columnDefs : [{
				field : "code",
				enableSorting: true,
				width : 80,
				displayName : "Code",
				cellTemplate: linkCellTemplateWithBackground,
				cellClass: "grid-align-center"
			}, {
				field : "description",
				enableSorting: false,
				cellTemplate: linkCellTemplateWithBackground,
				displayName : "Description",
				cellTooltip: function(row,col){if (row.entity.observations) return (row.entity.observations); else return ('Pas d\'obervations particulières');}
			}, {
				field : "observations",
				enableSorting: false,
				visible: false,
				displayName : "Observations"
			}, {
				field : "status",
				width: 70,
				displayName: 'Statut',
				cellClass: "grid-align-center",
				visible : false
			}, {
				field : "transparent",
				width: 70,
				displayName: 'transparent',
				cellClass: "grid-align-center",
				visible : false
			}, {
				field : "customerCode",
				cellClass: "grid-align-center",
				displayName: 'Client',
				width: 65,
				visible : false
			}, {
				field : "productionTypeDescription",
				displayName : "Type production",
				cellClass: "grid-align-center",
				enableSorting: true,
				visible: false,
			}, {
				field : "quotesSum",
				headerTooltip: 'Somme des devis acceptés',
				visible : false,
				enableFiltering: false,
				displayName : "Ventes",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "extraFundings",
				headerTooltip: 'Reports d\'exercice antérieur',
				visible : false,
				enableFiltering: false,
				displayName : "Report précédent",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "carriedForwardFundings",
				headerTooltip: 'Reports exercice suivant',
				visible : false,
				enableFiltering: false,
				displayName : "Report suivant",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "subProdFunding",
				headerTooltip: 'Financement issu d\'un dossier commercial global',
				visible : false,
				enableFiltering: false,
				displayName : "Financement sous-dossier",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "PM",
				enableSorting: true,
				width : 65,
				displayName : "PM",
				cellClass: "grid-align-center"
			}, {
				field : "date",
				enableSorting: true,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				width : 110,
				visible: false,
				displayName : "Date création",
				enableFiltering: false,
				cellClass: "grid-align-center"
			}, {
				field : "updateDate",
				enableSorting: true,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				width : 110,
				visible: false,
				displayName : "Date MAJ",
				enableFiltering: false,
				cellClass: "grid-align-center"
			}, {
				field : "estimatedFundings",
				pinnable: true,
				displayName : "Devis interne",
				width : 130,
				visible : false,
				cellFilter: "currency",
				cellClass: "grid-align-right"
			}, {
				field : "pipeFundingSum",
				headerTooltip: 'Pipe commercial',
				enableFiltering: false,
				displayName : "Pipe",
				width : 115,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "pendingQuotesSum",
				headerTooltip: 'Somme des devis en attente',
				enableFiltering: false,
				displayName : "Devis en attente",
				width : 115,
				visible: false,
				cellFilter: "currency",
				type: 'number',
				cellTemplate: fundingCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellFilter: 'currency',
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "fundingsSum",
				headerTooltip: "CA (report / ventes / dossier commercial) [les dossiers annulés ne sont pas comptés]",
				footerTooltip: "CA (report / ventes / dossier commercial) [les dossiers annulés ne sont pas comptés]",
				pinnable: true,
				displayName : 'CA',
				enableFiltering: false,
				width : 115,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: sumProjectsFundings,
				cellTemplate: fundingCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "expensesSum",
				headerTooltip: "Notes frais saisies à date",
				pinnable: true,
				displayName : 'Frais saisis',
				enableFiltering: false,
				width : 115,
				visible: false,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: sumProjectsExpenses,
				cellTemplate: accountedCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "invoicesSum",
				headerTooltip: "Factures saisies à dates",
				pinnable: true,
				displayName : 'Factures saisies',
				enableFiltering: false,
				width : 115,
				visible: false,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				aggregationType: sumProjectsInvoices,
				cellTemplate: accountedCellTemplate,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "accountedCost",
				pinnable: true,
				headerTooltip: 'Factures et notes de frais constatées',
				displayName : "Comptabilisé",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				cellTemplate: accountedCellTemplate,
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				cellTooltip: 'Frais et Factures'
			}, {
				field: "totalWages",
				pinnable: true,
				displayName : "Salaires constatés",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				aggregationType: sumProjectsTotalWages,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				cellTooltip: 'Salaires',
				visible : false
			}, {
				field : "totalCost",
				pinnable: true,
				displayName : "Coût constatés",
				width : 105,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				enableFiltering: false,
				//aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationType: sumProjectsTotalCosts,
				aggregationHideLabel: true,
				cellTemplate: costCellTemplate,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				headerTooltip: 'Total des coûts constatés(Frais, Factures, Piges) [les dossiers annulés ne sont pas comptés]',
				footerTooltip: 'Total des coûts constatés(Frais, Factures, Piges) [les dossiers annulés ne sont pas comptés]'
				}, {
				field : "finalTotalCost",
				pinnable: true,
				displayName : "Coûts estimés",
				width : 105,
				visible: true,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				type: 'number',
				enableFiltering: false,
				aggregationType: sumProjectsFinalCosts,
				//aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				cellTemplate: costCellTemplate,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right",
				headerTooltip: 'Coût total final [estimation]',
				footerTooltip: 'Coût total final [estimation]'
				}, {
				field : "currentMargin",
				visible : false,
				displayName : "Marge Act.",
				cellFilter: "number:0",
				enableFiltering: false,
				width : 105,
				type: 'number',
				cellClass: "grid-align-center"
			}, {
				field : "estimatedMargin",
				pinnable: true,
				displayName : "Marge finale",
				enableFiltering: false,
				headerTooltip:'Pourcentage de marge finale estimée si dossier en cours ou constatée si dossier terminé',
				type: 'number',
				cellFilter: "number:1",
				footerCellFilter: "number:2",
				aggregationType: getGlobalMargin,
				aggregationHideLabel: true,
				width : 70,
				cellTemplate: marginCellTemplate,
				footerCellClass: "grid-align-center",
				cellClass: "grid-align-center"
			}, {
				field : "currentGrossMargin",
				pinnable: true,
				displayName : "Marge actuelle",
				enableFiltering: false,
				headerTooltip:'Pourcentage de marge actuel',
				type: 'number',
				cellFilter: "number:1",
				cellTemplate: marginCellTemplate,
				visible: false,
				width : 70,
				cellClass: "grid-align-center"
			}, {
				field : "marginAmount",
				pinnable: true,
				displayName : "Marge brute",
				headerTooltip:'Montant de marge finale estimée si dossier en cours ou constatée si dossier terminé',
				enableFiltering: false,
				width : 105,
				type: 'number',
				cellFilter: "currency",
				footerCellFilter: 'currency',
				aggregationType: sumProjectsGrossMargin,
				aggregationHideLabel: true,
				cellClass: "grid-align-right",
				footerCellClass: "grid-align-right"
			}, {
				field : "actions",
				pinnable: false,
				displayName : "",
				width : 50,
				cellTemplate: actionLinks,
				enableFiltering: false,
				cellClass: "grid-align-center",
				enableSorting: false,
				enableColumnMenu: false
			}]
		};

		$scope.$on('ngGridEventFilter', function(newFilterText){
			if($scope.rowsSelection.length){
				$scope.gridSettings.$gridScope.toggleSelectAll(null, false);
				var selectAllHeader = angular.element(".ngSelectionHeader").scope();
				if(selectAllHeader) selectAllHeader.allSelected = false;
			}
		});

		$scope.$on('ngGridEventSorted', function(newFilterText){
			if($scope.rowsSelection.length){
				$scope.gridSettings.$gridScope.toggleSelectAll(null, false);
				var selectAllHeader = angular.element(".ngSelectionHeader").scope();
				if(selectAllHeader) selectAllHeader.allSelected = false;
			}
		});

		$scope.getProductions();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewQuotesCtrl', ['$scope', '$rootScope', '$uibModal', 'uiGridConstants', '$log', '$http', '$timeout', '$state', '$filter', 'jsonData', 'localStorageService', viewQuotesCtrl]);


	function viewQuotesCtrl($scope, $rootScope, $uibModal, uiGridConstants, $log, $http, $timeout, $state, $filter, jsonData, localStorageService) {

		$scope.statusFilter = localStorageService.get('quotesStatusFilters') ? localStorageService.get('quotesStatusFilters') : {pending: false, proposed: true, engaged: true, ordered: true, invoiced: true, canceled: true};
		$scope.quotes = [];
		$scope.selectedQuotesTotal=0;

		$scope.getQuotes = function(){
			jsonData.getQuotes({}).then(function(data){
				$scope.quotes = data;
				$scope.filterDatas();
			});
		};

		$scope.filterDatas = function(){
			localStorageService.set('quotesStatusFilters', $scope.statusFilter);
			var quotesArray = [];
			angular.forEach($scope.quotes, function(quote, key){
				if(($scope.statusFilter.pending && quote.status==='null') || ($scope.statusFilter.proposed && quote.status==='0') || ($scope.statusFilter.engaged && quote.status==='1') || ($scope.statusFilter.ordered && quote.status==='2') || ($scope.statusFilter.invoiced && quote.status==='3') || ($scope.statusFilter.canceled && quote.status==='4')) {
					var invoiceDate, orderDate;
					if (quote.invoiceDate)
						invoiceDate = new Date(quote.invoiceDate.substring(0, 10));
					else
						invoiceDate = undefined;
					if (quote.orderDate)
						orderDate = new Date(quote.orderDate.substring(0, 10));
					else
						orderDate = undefined;
					quotesArray.push({
						'quoteId'			: quote.quoteId,
						'productionCode'	: quote.productionCode,
						'customerName'		: quote.customerName,
						'customerCode'		: quote.customerCode,
						'status'			: quote.status,
						'userId'			: quote.userId,
						'fileName' 			: quote.fileName,
						'invoiceDate' 		: invoiceDate,
						'orderDate'			: orderDate,
						'amount'			: parseFloat(quote.amount),
						'number'			: quote.quoteNumber,
						'customer'			: quote.customerRecipient,
						'description'		: quote.description,
						'observations'		: quote.observations
					});
				}
			});
			$scope.gridSettings.data = quotesArray;
		};

		$scope.editQuote = function (row){
			var modalInstance = $scope.openModal('./partials/modalEditQuote.html', 'modalEditQuoteCtrl', {'productionCode' : row.productionCode, 'customerCode' : row.customerCode, 'quoteNumber' : row.number}, '');

			modalInstance.result.then(function (quoteFromModal) {
				$http.post('/json/setQuote.php', {
					'quoteId'			: quoteFromModal.quoteId,
					'index'				: quoteFromModal.index,
					'quoteNumber'		: quoteFromModal.quoteNumber,
					'deliveryDate' 		: quoteFromModal.deliveryDate===null ? null : $filter('date')(quoteFromModal.deliveryDate, 'yyyy-MM-dd'),
					'productionCode'	: row.productionCode,
					'observations'		: quoteFromModal.observations,
					'customerRecipient'	: quoteFromModal.customerRecipient,
					'description'		: quoteFromModal.description,
					'customerCode'		: row.customerCode,
					'status'			: quoteFromModal.status
				}).then(
					function(response) {
						$scope.getQuotes();
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			});
		};

		function sumSelectedRows(){
			$scope.selectedQuotesTotal=0;
			var rowsSelection = $scope.gridApi.selection.getSelectedRows();
			angular.forEach(rowsSelection, function(row, index){
				$scope.selectedQuotesTotal += row.amount;
			});
		}

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-warning\': row.entity.status==\'1\', \'bg-success\': row.entity.status==\'2\', \'bg-info\': row.entity.status==\'3\', \'bg-danger\': row.entity.status==\'4\'}"><a href ui-sref="viewProductionTab({ productionId : row.entity.productionCode, tabNumber: 2})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a></div>';

		var statusCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP" ng-class="{\'bg-warning\': row.entity.status==\'1\', \'bg-success\': row.entity.status==\'2\', \'bg-info\': row.entity.status==\'3\', \'bg-danger\': row.entity.status==\'4\'}">{{COL_FIELD CUSTOM_FILTERS}}</div>';

		var actionLinks = '<div class="ui-grid-cell-contents" title="TOOLTIP"><span class="link text-info glyphicon glyphicon-edit" aria-hidden="true" ng-click="grid.appScope.editQuote(row.entity)"></span>&nbsp;&nbsp;<a ng-href="/downloadFile.php?fileName={{row.entity.fileName}}&customerCode={{row.entity.customerCode}}" ng-click="$event.stopPropagation();"><span class="text-info glyphicon glyphicon-download" aria-hidden="true"></span></a></div>';


		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'devis.xlsx',
			exporterSuppressColumns: [ 'actions' ],
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			enableCellSelection: false,
			enableRowSelection: true,
			enableFiltering: true,
			showColumnFooter: true,
			headerRowHeight: 43,
			showSelectionCheckbox: true,
			multiSelect: true,
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
				gridApi.selection.on.rowSelectionChanged($scope, sumSelectedRows);
				gridApi.selection.on.rowSelectionChangedBatch($scope, sumSelectedRows);
			},
			columnDefs : [{
				field : "productionCode",
				sortable: false,
				width : 80,
				displayName : "Dossier",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "customerCode",
				visible : false
			}, {
				field : "quoteId",
				visible : false
			}, {
				field : "number",
				sortable: false,
				width : 90,
				displayName : "Numéro",
				cellTemplate: statusCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "status",
				visible : false
			}, {
				field : "userId",
				sortable: true,
				width: "65",
				cellClass: "grid-align-center",
				displayName : "PM"
			}, {
				field : "customer",
				sortable: false,
				width : "15%",
				displayName : "Destinataire"
			}, {
				field : "description",
				enableSorting: false,
				sortable: false,
				displayName : "Description"
			}, {
				field : "observations",
				sortable: false,
				enableSorting: false,
				width : "15%",
				displayName : "Observations"
			}, {
				field : "orderDate",
				width : 140,
				sortable: true,
				visible: true,
				enableFiltering: false,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				cellClass: "grid-align-center",
				displayName : "Date commande"
			}, {
				field : "invoiceDate",
				width : 140,
				sortable: true,
				visible: true,
				enableFiltering: false,
				type: "date",
				cellFilter: "date : 'dd/MM'",
				cellClass: "grid-align-center",
				displayName : "Date facture"
			}, {
				field : "amount",
				displayName : "Montant",
				width : 115,
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
				cellClass: "grid-align-right"
			}, {
				field : "fileName",
				cellTemplate: actionLinks,
				enableSorting: false,
				enableFiltering: false,
				displayName : "",
				width : 50,
				cellClass: "grid-align-center"
			}]
		};

		$scope.getQuotes();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewSuppliersCtrl', ['$scope', '$rootScope', 'uiGridConstants', '$log', '$http', 'jsonData', viewSuppliersCtrl]);

	function viewSuppliersCtrl($scope, $rootScope, uiGridConstants, $log, $http, jsonData) {
		$scope.suppliersArray = [];

		$scope.getSuppliers = function(){
			jsonData.getSuppliersList().then(function(suppliers){
				angular.forEach(suppliers, function(supplier, key){
					$scope.suppliersArray.push({
						'supplierName'	: supplier.description,
						'supplierId'	: supplier.supplierId,
						'amountSum'		: supplier.amountSum
					});
				});
			});
		};

		var actionLinks = '<div class="ui-grid-cell-contents" title="TOOLTIP"></div>';

		var linkCellTemplate = '<div class="ui-grid-cell-contents" title="TOOLTIP"><a href ui-sref="viewInvoices({ supplier : row.entity.supplierName})" ng-click="$event.stopPropagation()">{{COL_FIELD CUSTOM_FILTERS}}</a></div>';

		$scope.gridSettings = {
			enableGridMenu: true,
			exporterExcelFilename: 'fournisseurs.xlsx',
			exporterSuppressColumns: [ 'supplierId' ],
			exporterFieldCallback: function ( grid, row, col, value ) {
				return value;
			},
			exporterMenuPdf: false,
			exporterMenuCsv: false,
			data : "suppliersArray",
			enableFiltering: true,
			showColumnFooter: true,
			onRegisterApi: function(gridApi){
				$scope.gridApi = gridApi;
			},
			columnDefs : [{
				field : "supplierName",
				sortable: false,
				displayName : "Nom",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-left"
			}, {
				field : "amountSum",
				sortable: true,
				displayName : "Total",
				cellFilter: "currency",
				footerCellFilter: 'currency',
				enableFiltering: false,
				type: 'number',
				aggregationType: uiGridConstants.aggregationTypes.sum,
				aggregationHideLabel: true,
				footerCellClass: "grid-align-right",
				cellClass: "grid-align-right",
				width : "200"
			}, {
				field : "supplierId",
				displayName : "",
				sortable: false,
				width : 70,
				enableFiltering: false,
				visible: false,
				cellClass: "grid-align-center"
			}]
		};

		$scope.getSuppliers();
	}
})();

(function() {
	'use strict';

	angular
		.module('main')
		.controller('viewWagesCtrl', ['$scope', '$rootScope', '$modal', '$http', '$timeout', 'jsonData', viewWagesCtrl]);

	function viewWagesCtrl($scope, $rootScope, $modal, $http, $timeout, jsonData) {
		$scope.filterOptions = {
			filterText: '',
	        useExternalFilter: false
		};

		$scope.filterValues = {
			code: '',
			beneficiary: ''
		};

		$scope.wagesArray = [];

		$scope.getWages = function(){
			jsonData.getWages().then(function(data){
				$scope.wages = data;
				angular.forEach($scope.wages, function(wage, key){
					$scope.wagesArray.push({
						'productionCode' : wage.productionCode,
						'date' : wage.date,
						'estimatedAmount' : parseFloat(wage.estimatedAmount),
						'amount' : parseFloat(wage.amount),
						'beneficiary' : wage.beneficiary
					});
				});
			});
		};

		$scope.filterDatas = function (){
			jsonData.getProductions().then(function(data){
				$scope.productionsArray.length=0;
				angular.forEach($scope.productions, function(production, key){
					if(production.customerCode=='RTE'){
						totalCost = parseFloat(production.wagesSum) + parseFloat(production.wagesSum) + parseFloat(production.expensesSum);
						estimatedTotalCost = parseFloat(production.wagesEstimatedSum) + parseFloat(production.wagesEstimatedSum) + parseFloat(production.expensesEstimatedSum);
						$scope.productionsArray.push({
							'code' : production.code,
							'date' : production.date,
							'description' : production.description,
							'fundingsSum' : parseFloat(production.fundingsSum),
							'totalCost' : totalCost,
							'estimatedTotalCost' : estimatedTotalCost,
							'margin' : (1 - totalCost / parseFloat(production.fundingsSum)) * 100,
							'estimatedMargin' : (1 - estimatedTotalCost / parseFloat(production.fundingsSum)) * 100
						});
					}
				});
			});
		};

		$scope.setFilterText = function(){
			filterValues = [];
			angular.forEach($scope.filterValues, function(value, key){
				if(value.length){
					filterValues.push(key+":"+value);
				}
			});
			$scope.filterOptions.filterText = filterValues.join(';');
		};

		$scope.logRow = function(data){
			var modalInstance = $modal.open({
				templateUrl: './partials/modalViewProduction.html',
				controller: 'modalViewProductionCtrl',
				resolve: {
			        params: function () {
			          return data;
			        }
				},
				size: 'lg'
			});
		};


		var codeHeaderCellTemplate = '<div class="ngHeaderSortColumn {{col.headerClass}}" ng-style="{cursor: col.cursor}" ng-class="{ ngSorted: !noSortVisible }">'+
			'<div ng-click="col.sort($event)" ng-class="\'colt\' + col.index" class="ngHeaderText"><input type="text" class="form-control input-sm" placeholder="code ..." ng-model="filterValues.code" ng-change="setFilterText()" /></div>'+
			'<div ng-show="col.resizable" class="ngHeaderGrip" ng-click="col.gripClick($event)" ng-mousedown="col.gripOnMouseDown($event)"></div>';

		var beneficiaryHeaderCellTemplate = '<div class="ngHeaderSortColumn {{col.headerClass}}" ng-style="{cursor: col.cursor}" ng-class="{ ngSorted: !noSortVisible }">'+
			'<div ng-click="col.sort($event)" ng-class="\'colt\' + col.index" class="ngHeaderText"><input type="text" class="form-control input-sm" placeholder="beneficiary ..." ng-model="filterValues.beneficiary" ng-change="setFilterText()" /></div>'+
			'<div ng-show="col.resizable" class="ngHeaderGrip" ng-click="col.gripClick($event)" ng-mousedown="col.gripOnMouseDown($event)"></div>';

		var linkCellTemplate = '<div class="ngCellText" ng-class="col.colIndex()">' +
	    	'<a href ng-click="logRow(row.entity.productionCode)">{{row.getProperty(col.field)}}</a>' +
	    	'</div>';

		$scope.gridSettings = {
			data : "wagesArray",
			enableCellSelection: false,
			enableRowSelection: false,
			enableCellEdit: false,
			enableColumnResize : true,
			filterOptions: $scope.filterOptions,
			showFooter: true,
	        headerRowHeight: 43,
			columnDefs : [{
				field : "productionCode",
				sortable: false,
				headerCellTemplate: codeHeaderCellTemplate,
				width : 100,
				displayName : "Code",
				cellTemplate: linkCellTemplate,
				cellClass: "grid-align-center"
			}, {
				field : "date",
				sortable: true,
				cellFilter: "date",
				displayName : "Date"
			}, {
				field : "beneficiary",
				sortable: false,
				headerCellTemplate: beneficiaryHeaderCellTemplate,
				displayName : "Beneficiary"
			}, {
				field : "estimatedAmount",
				displayName : "int. Quotes",
				cellFilter: "currency",
				width : "15%",
				cellClass: "grid-align-right"
			}, {
				field : "amount",
				displayName : "Amount",
				cellFilter: "currency",
				width : "15%",
				cellClass: "grid-align-right"
			}, {
				field : "delta",
				displayName : "Delta",
				cellFilter: "currency",
				width : "15%",
				cellClass: "grid-align-right"
			}]
		};

		$scope.getWages();
	}
})();

(function() {
	angular
		.module('main')
		.directive('resize', ['$window', function($window) {
			return function(scope, element, attr) {

				var w = angular.element($window);
				scope.$watch(function() {
					return {
						'h': $(window).height(),
						'w': $(window).width()
					};
				}, function(newValue, oldValue) {
					scope.windowHeight = newValue.h;
					scope.windowWidth = newValue.w;

					scope.resizeWithOffset = function(offsetH) {

						scope.$eval(attr.notifier);

						return {
							'height': (newValue.h - offsetH) + 'px'
							//,'width': (newValue.w - 100) + 'px'
						};
					};

				}, true);

				w.bind('resize', function() {
					scope.$applyAsync();
				});
			};
		}]);
})();
(function() {
	angular
		.module('main')
		.filter('bytes', function() {
			'use strict';

			return function(bytes, precision) {
				if (bytes===0 || isNaN(parseFloat(bytes)) || !isFinite(bytes))
					return '-';
				if ( typeof precision === 'undefined')
					precision = 1;
				var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'], number = Math.floor(Math.log(bytes) / Math.log(1024));
				return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
			};
		});
})();

(function() {
	angular
		.module('main')
		.filter('prettyJSON', function() {
			'use strict';

			return function(json) {
				return JSON ? JSON.stringify(json, null, '  ') : 'your browser doesnt support JSON so cant pretty print';
			};
		});
})();
(function() {
	angular
	.module('main')
	.filter('monthByIndex', function() {
		'use strict';

		return function(index) {
			switch (index) {
				case '0':
					return 'janvier';
				case '1':
					return 'février';
				case '2':
					return 'mars';
				case '3':
					return 'avril';
				case '4':
					return 'mai';
				case '5':
					return 'juin';
				case '6':
					return 'juillet';
				case '7':
					return 'aout';
				case '8':
					return 'septembre';
				case '9':
					return 'octobre';
				case '10':
					return 'novembre';
				case '11':
					return 'décembre';

			}
			return (undefined);
		};
	});
})();

(function() {
		angular
			.module('main')
			.filter('numberFixedLen', function() {
			return function(n, len) {
				var num = parseInt(n, 10);
				len = parseInt(len, 10);
				if (isNaN(num) || isNaN(len)) {
					return n;
				}
				num = '' + num;
				while (num.length < len) {
					num = '0' + num;
				}
				return num;
			};
		});
})();

(function() {
	angular
		.module('main')
		.filter('percentage', ['$filter', function($filter) {
			'use strict';
			return function(input, decimals) {
				return $filter('number')(input * 100, decimals) + '%';
			};
		}]);
})();

(function() {
	angular
		.module('main')
		.filter('truncate', function() {
			return function(text, length, end) {
				if (isNaN(length))
					length = 10;

				if (end === undefined)
					end = "...";

				if (text.length <= length || text.length - end.length <= length) {
					return text;
				} else {
					return String(text).substring(0, length - end.length) + end;
				}

			};
		});
})();

(function() {
	"use strict";

	angular
		.module("ui.checkbox", [])
		.directive("checkbox", function() {
			return {
				scope: {},
				require: "ngModel",
				restrict: "E",
				replace: "true",
				template: "<button type=\"button\" ng-style=\"stylebtn\" class=\"btn btn-default\" ng-class=\"{'btn-xs': size==='default', 'btn-sm': size==='large', 'btn-lg': size==='largest'}\">" +
					"<span ng-style=\"styleicon\" class=\"glyphicon\" ng-class=\"{'glyphicon-ok': checked===true}\"></span>" +
					"</button>",
				link: function(scope, elem, attrs, modelCtrl) {
					scope.size = "default";
					// Default Button Styling
					scope.stylebtn = {};
					// Default Checkmark Styling
					scope.styleicon = {"width": "10px", "left": "-1px"};
					// If size is undefined, Checkbox has normal size (Bootstrap 'xs')
					if(attrs.large !== undefined) {
						scope.size = "large";
						scope.stylebtn = {"padding-top": "2px", "padding-bottom": "2px", "height": "30px"};
						scope.styleicon = {"width": "8px", "left": "-5px", "font-size": "17px"};
					}
					if(attrs.larger !== undefined) {
						scope.size = "larger";
						scope.stylebtn = {"padding-top": "2px", "padding-bottom": "2px", "height": "34px"};
						scope.styleicon = {"width": "8px", "left": "-8px", "font-size": "22px"};
					}
					if(attrs.largest !== undefined) {
						scope.size = "largest";
						scope.stylebtn = {"padding-top": "2px", "padding-bottom": "2px", "height": "45px"};
						scope.styleicon = {"width": "11px", "left": "-11px", "font-size": "30px"};
					}

					var trueValue = true;
					var falseValue = false;

					// If defined set true value
					if(attrs.ngTrueValue !== undefined) {
						trueValue = attrs.ngTrueValue;
					}
					// If defined set false value
					if(attrs.ngFalseValue !== undefined) {
						falseValue = attrs.ngFalseValue;
					}

					// Check if name attribute is set and if so add it to the DOM element
					if(scope.name !== undefined) {
						elem.name = scope.name;
					}

					// Update element when model changes
					scope.$watch(function() {
						if(modelCtrl.$modelValue === trueValue || modelCtrl.$modelValue === true) {
							modelCtrl.$setViewValue(trueValue);
						} else {
							modelCtrl.$setViewValue(falseValue);
						}
						return modelCtrl.$modelValue;
					}, function(newVal, oldVal) {
						scope.checked = modelCtrl.$modelValue === trueValue;
					}, true);

					// On click swap value and trigger onChange function
					elem.bind("click", function() {
						scope.$apply(function() {
							if(modelCtrl.$modelValue === falseValue) {
								modelCtrl.$setViewValue(trueValue);
							} else {
								modelCtrl.$setViewValue(falseValue);
							}
						});
					});
				}
			};
	});
})();

(function() {
	'use strict';

	angular
		.module('main')
		.factory('jsonData', ['$q', '$timeout', '$http', jsonData]);

	function jsonData($q, $timeout, $http) {
		return {
			getActiveCustomers : function() {
				return $http.post('/json/getActiveCustomers.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getCustomers : function() {
				return $http.post('/json/getCustomers.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getProductions : function(customerCode) {
				return $http.post('/json/getProductions.php', {
					'customerCode' : customerCode
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getProduction : function(productionId) {
				return $http.post('/json/getProduction.php', {
					'code' : productionId
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getSuppliers : function(params) {
				return $http.post('/json/getSuppliers.php', {
					'param' : params
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getSuppliersList : function(params) {
				return $http.post('/json/getSuppliersList.php', {
					'param' : params
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getInvoices : function() {
				return $http.post('/json/getInvoices.php', {}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getQuotes : function(params) {
				return $http.post('/json/getQuotes.php', {
					'param' : params
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getWages : function() {
				return $http.post('/json/getWages.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
				},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getUsers : function() {
				return $http.post('/json/getUsers.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
				},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getEmployees : function() {
				return $http.post('/json/getEmployees.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getEmployeesTotals : function() {
				return $http.post('/json/getEmployeesTotals.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getProductionTypes : function() {
				return $http.post('/json/getProductionTypes.php', {
					'param' : 'param'
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getWorkPeriods : function(employeeId) {
				return $http.post('/json/getEmployeePeriods.php', {
					'employeeId' : employeeId
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			},
			getExpenses : function(params) {
				return $http.post('/json/getExpenses.php', {
					'params' : params
				}).then(
					function(response) {
						return(response.data);
					},
					function(httpError){
						throw httpError.status + " : " + httpError.data;
					}
				);
			}
		};
	}
})();

